import { useState } from "react";
import {
  DownloadingLoader,
  PdfDownloading,
} from "../../components/PdfDownloading";
import mdFilePath from "../../content/terms-of-service-for-sonata.md";
import { useParseMarkdown } from "../../hooks/useParseMarkdown";
import Markdown from "markdown-to-jsx";
import { SEO } from "../../components/meta/SEO"
import meta from "../../components/meta/meta.json"

const TermsOfService = ({ downloadBtn = true, isClone = false }) => {
  const { content, frontmatter } = useParseMarkdown(mdFilePath);
  const [downloading, setDownloading] = useState(() => false);

  return (
    <>
      <SEO
        title={meta?.termsOfService?.title}
        description={meta?.termsOfService?.description}
      />
      <DownloadingLoader downloading={downloading}
        component="terms-of-service"
      />
      <div
        className={isClone ? "terms-of-service clone-comp" : "terms-of-service"}
        id={isClone ? "terms-of-service" : null}
      >
        <h1 className="p-title">{frontmatter?.title}</h1>
        <div className="effective-date download-pdf">
          <time>{frontmatter?.date}</time>
          {downloadBtn &&
            <PdfDownloading
              setDownloading={setDownloading}
              policy="terms-of-service"
            />
          }
        </div>
        <div className="terms-of-service__content">
          <Markdown>{content}</Markdown>
        </div>
      </div>
    </>
  );
};

export default TermsOfService;
