import { createContext, useEffect, useReducer } from "react";

export const WaitListContext = createContext();

export const WaitListReducer = (state, action) => {
  switch (action.type) {
    case "WAITLIST_CONTEXT":
      return { waitlistState: action.payload.waitlistState };
    default:
      return state;
  }
};

export const WaitListContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(WaitListReducer, {
    waitlistState: false,
  });

  useEffect(() => {
    const waitlistState = localStorage.getItem("addedOnWaitList");
    if (waitlistState) {
      dispatch({
        type: "WAITLIST_CONTEXT",
        payload: { waitlistState: JSON.parse(waitlistState) },
      });
    }
  }, []);
  // console.log(state, "waitlistState");
  return (
    <WaitListContext.Provider value={{ ...state, dispatch }}>
      {children}
    </WaitListContext.Provider>
  );
};
