import { useState } from "react";
import {
  DownloadingLoader,
  PdfDownloading,
} from "../../components/PdfDownloading";
import mdFilePath from "../../content/cookie-policy-sonata.md";
import { useParseMarkdown } from "../../hooks/useParseMarkdown";
import Markdown from "markdown-to-jsx";
import { SEO } from "../../components/meta/SEO"
import meta from "../../components/meta/meta.json"

const CookiesPolicy = ({ downloadBtn = true, isClone = false }) => {
  const { content, frontmatter } = useParseMarkdown(mdFilePath)
  const [downloading, setDownloading] = useState(() => false)

  return (
    <>
      <SEO
        title={meta?.cookiesPolicy?.title}
        description={meta?.cookiesPolicy?.description}
      />
      <DownloadingLoader downloading={downloading} component="cookie-policy-sonata" />
      <div
        className={isClone ? "cookie-policy-sonata clone-comp" : "cookie-policy-sonata"}
        id={isClone ? "cookie-policy-sonata" : null}
      >
        <h1 className="p-title">{frontmatter?.title}</h1>
        <div className="effective-date download-pdf">
          <time>{frontmatter?.date}</time>
          {downloadBtn &&
            <PdfDownloading
              setDownloading={setDownloading}
              policy="cookie-policy-sonata"
            />
          }
        </div>
        <div className="cookie-policy-sonata__content">
          <Markdown>{content}</Markdown>
          <div className="effective-date">
            <time>{frontmatter?.date}</time>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookiesPolicy;
