import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { useParseMarkdown } from "../../hooks/useParseMarkdown"
import Markdown from "markdown-to-jsx"
import BackArrowPressRelease from "../../components/svg/BackArrowPressRelease"
import { SEO } from "../../components/meta/SEO"
import PressReleaseBottom from "./PressReleaseBottom"

const PressReleases = ({
  setIsPressRelease,
  pressFile = "",
  isPressRelease = false
}) => {
  const [mdFilePath, setMdFilePath] = useState(() => "")
  const { content, frontmatter } = useParseMarkdown(mdFilePath)
  const [showComponent, setShowComponent] = useState(() => false)
  const location = useLocation()

  useEffect(() => {
    if (pressFile) {
      setIsPressRelease(true)
      import(`../../content/press-releases/${pressFile}.md`).then((mdFile) => {
        setMdFilePath(mdFile.default)
      }).catch((err) => { throw err })
    }
  }, [pressFile])

  useEffect(() => {
    setShowComponent(false)
    const timer = setTimeout(() => {
      setShowComponent(true)
    }, 400)

    return () => clearTimeout(timer)
  }, [location?.hash])

  return isPressRelease && (
    <>
      <SEO
        title={`${frontmatter?.metaTitle} | Sonata`}
        description={frontmatter?.metaDescription}
      />
      {showComponent &&
        <div className="press-releases__content">
          <div className="p-title">
            <h1>
              <Link to="/press-releases">
                <BackArrowPressRelease />
              </Link>
              {frontmatter?.title}
            </h1>
          </div>
          <Markdown>
            {content}
          </Markdown>
          <PressReleaseBottom
            isBottomContent={frontmatter?.isBottomContent}
            bottomContentKey={frontmatter?.bottomContentKey}
            pressBottomContent={frontmatter?.pressBottomContent}
            pressFile={pressFile}
          />
        </div>
      }
    </>
  )
}

export default PressReleases