import * as React from "react";
const PdfRightArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={34}
    fill="none"
    {...props}
  >
    <path
      stroke="#3C0061"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.333}
      d="m15.333 22 5-5-5-5"
    />
    <path
      stroke="#3C0061"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.333}
      d="M32 5.333A3.333 3.333 0 0 0 28.667 2H5.333A3.333 3.333 0 0 0 2 5.333v23.334A3.333 3.333 0 0 0 5.333 32h23.334A3.333 3.333 0 0 0 32 28.667V5.333Z"
    />
  </svg>
);
export default PdfRightArrow;
