import { Route, Routes } from "react-router-dom"
import CodeExpired from "./CodeExpired"
import UnrecognisedCode from "./UnrecognisedCode"
import EmailVerified from "./EmailVerified"
import RemovedFromWaitlist from "./RemovedFromWaitlist"
import UnsubFromWaitlist from "./UnsubFromWaitlist"
import GenericSuccess from "./GenericSuccess"
import ConfirmEmail from "./ConfirmEmail"

const ApiResponsesRouter = () => {
  return (
    <Routes>
      <Route path="/code-expired" element={<CodeExpired />} />
      <Route path="/unrecognised-code" element={<UnrecognisedCode />} />
      <Route path="/user-email-verification" element={<EmailVerified />} />
      <Route path="/optout-list" element={<RemovedFromWaitlist />} />
      <Route path="/unsubscribe-list" element={<UnsubFromWaitlist />} />
      <Route path="/success" element={<GenericSuccess />} />
      <Route path="/confirm-email" element={<ConfirmEmail />} />
    </Routes>
  )
}

export default ApiResponsesRouter