const AttentionIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width ? props?.width : 40}
    height={props?.height ? props?.height : 40}
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      stroke="#3C0061"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11.667 5H8.333A3.333 3.333 0 0 0 5 8.333v3.334m0 16.666v3.334A3.333 3.333 0 0 0 8.333 35h3.334m16.666 0h3.334A3.333 3.333 0 0 0 35 31.667v-3.334m0-16.666V8.333A3.333 3.333 0 0 0 31.667 5h-3.334M20 20a5 5 0 1 0 0-10 5 5 0 0 0 0 10ZM28.333 26.667c0-3.684-3.732-6.667-8.334-6.667-4.601 0-8.333 2.983-8.333 6.667"
    />
  </svg>
);
export default AttentionIcon;
