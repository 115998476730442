import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import PdfLeftArrow from "../../components/svg/PdfLeftArrow";
import PdfRightArrow from "../../components/svg/PdfRightArrow";
import BigPdfLeftArrow from "../../components/svg/BigPdfLeftArrow";
import BigPdfRightArrow from "../../components/svg/BigPdfRightArrow";
import Select from "react-select";
import { useSwipeable } from "react-swipeable"
import { useDisableScroll } from "../../hooks/useDisableScroll";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.min.js`;

const whitePaperFileName = process.env.REACT_APP_WHITEPAPER_FILENAME

const style = {
  control: (base) => ({
    ...base,
    border: "2px solid #F3EEF9",
    boxShadow: "none",
    borderRadius: "8px",
    outline: "none",
    "&:hover": {
      border: "transparent solid #F3EEF9",
    },
    cursor: "pointer",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),

  container: (base, state) => ({
    ...base,
    width: "80px",
    border: "none",
    cursor: "pointer",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transform: state.selectProps.menuIsOpen && "rotate(180deg)",
    transition: "all .2s ease",
  }),
  menu: (base) => ({
    ...base,
    border: "2px solid #F3EEF9",
    borderRadius: "8px",
  }),
  indicatorContainer: (base) => ({
    ...base,
    padding: "3px 8.5px",
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "3px 8.5px",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? "transparent" : null,
    color: state.isSelected ? "black" : null,
    padding: "4px 12px",
    cursor: "pointer",

    "&:active": {
      backgroundColor: "transparent",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  }),
};

const PdfViewerMbl = ({isBrowserSafari}) => {
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showCover, setShowCover] = useState(true);
  const [isDocLoaded, setIsDocLoaded] = useState(false)
  const [reRender, setReRender] = useState(() => false)
  const { ref: coverRef } = useDisableScroll()
  const { ref: docRef } = useDisableScroll(reRender)

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setShowCover(false);
    }
  };

  const handleNextPage = () => {
    if (currentPage < numPages - 1) {
      setCurrentPage(currentPage + 1);
      setShowCover(false);
    } else if (currentPage === numPages - 1) {
      setCurrentPage(currentPage + 1);
      setShowCover(false);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setIsDocLoaded(true);
  };

  const DoublePage = ({ pageNumber }) => (
    <div className="pdf-pages">
      <Page
        pageNumber={pageNumber}
        width={window.innerWidth / 1.5}
        height={"100%"}
        renderAnnotationLayer={false}
        renderTextLayer={false}
      />
    </div>
  );

  const handleCoverClick = () => {
    setShowCover(false);
  };

  const coverHandlers = useSwipeable({
    onSwipedLeft: () => handleCoverClick(),
    onSwipedRight: () => handleCoverClick()
  })

  const docHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentPage === numPages - 1) { } else { handleNextPage() }
    },
    onSwipedRight: () => {
      if (currentPage === 1) { } else { handlePreviousPage() }
    }
  })

  useEffect(() => {
    if (!showCover) {
      setReRender(true)
    }
  }, [showCover])

  return (
    <div>
      {showCover && (
        <div className="cover-container"
          {...coverHandlers}
        >
          {isDocLoaded && (
            <div className="clickable" onClick={handleCoverClick}>
              <BigPdfLeftArrow width={26} height={26} />
            </div>
          )}

          <div className="cover"
            ref={coverRef}
          >
            <Document
              file={require(`../../assets/files/${whitePaperFileName}`)}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                pageNumber={1}
                width={window.innerWidth / 1.5}
                height={700}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            </Document>
          </div>
          {isDocLoaded && (
            <div className="clickable" onClick={handleCoverClick}>
              <BigPdfRightArrow width={26} height={26} />
            </div>
          )}
        </div>
      )}

      {!showCover && (
        <div className="pdf-pages-container"
          {...docHandlers}
        >
          <div
            ref={docRef}
            className="pdf-document"
          >
            <Document
              file={require(`../../assets/files/${whitePaperFileName}`)}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <DoublePage pageNumber={currentPage} />
            </Document>
          </div>
        </div>
      )}
      {isDocLoaded && (
        <div className="pdf-nav"
        >
          {!showCover && (
            <div
              className="clickable"
              onClick={currentPage === 1 ? undefined : handlePreviousPage}
            >
              <PdfLeftArrow />
            </div>
          )}

          <div className="pdf-current">
            <div>Page:</div>
            <div className="dropdown-container">
              {showCover ? (
                `1`
              ) : (
                // <select
                //   value={currentPage}
                //   onChange={(e) => setCurrentPage(parseInt(e.target.value))}
                //   className="dropdown"
                // >
                //   {Array.from(Array(numPages).keys()).map((page) => (
                //     <option key={page + 1} value={page + 1}>
                //       {`${page + 1}`}
                //     </option>
                //   ))}
                // </select>
                <Select
                  value={{
                    value: currentPage,
                    label: `${currentPage}`,
                  }}
                  options={Array.from(Array(numPages).keys()).map((page) => ({
                    value: page + 1,
                    label: `${page + 1}`,
                  }))}
                  onChange={(selectedOption) =>
                    setCurrentPage(selectedOption.value)
                  }
                  className="dropdown"
                  styles={style}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                />
              )}
            </div>
            <div>/{numPages}</div>
          </div>
          {!showCover && (
            <div
              onClick={
                currentPage === numPages - 1 ? undefined : handleNextPage
              }
              className="clickable"
            >
              <PdfRightArrow />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PdfViewerMbl;
