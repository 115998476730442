const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={29}
    fill="none"
    {...props}
  >
    <path
      fill="#160323"
      d="m12.687 23.016-7.7-7.7a1.03 1.03 0 0 1-.249-.379 1.3 1.3 0 0 1-.072-.437 1.3 1.3 0 0 1 .072-.438 1.03 1.03 0 0 1 .249-.379l7.7-7.7c.214-.214.48-.326.801-.336.322-.01.599.103.832.336.233.214.355.481.365.802.01.32-.102.598-.336.831l-5.716 5.717H21.67c.33 0 .608.112.832.335.223.224.335.501.335.832 0 .33-.112.607-.335.83a1.13 1.13 0 0 1-.832.336H8.633l5.716 5.717c.214.214.326.486.336.817.01.33-.102.602-.336.816a1.06 1.06 0 0 1-.816.35c-.33 0-.613-.116-.846-.35Z"
    />
  </svg>
)
export default SvgComponent
