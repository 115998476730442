import CustomMessages from "../../components/customMessages/CustomMessage"
import data from "./data.json"
import VerifyEmailIcon from "../../components/svg/VerifyEmailIcon"
import { useWindowWidth } from "../../hooks/useWindowWidth"

const EmailVerified = () => {
  const screenWidth = useWindowWidth()

  return (
    <CustomMessages
      icon={
        screenWidth <= 767 ?
          <VerifyEmailIcon width={90} height={100} />
          :
          <VerifyEmailIcon />
      }
      data={data?.emailVerified}
      isChillaxHeading={true}
      isEmailVerified={true}
    />
  )
}

export default EmailVerified