const Error404 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width ? props?.width : 157}
    height={props?.height ? props?.height : 173}
    viewBox="0 0 157 173"
    fill="none"
    {...props}
  >
    <path
      fill="#FD5201"
      d="M35.375 17.5h17.25V.25h-17.25V17.5ZM35.375 17.5h-17.25v17.25h17.25V17.5ZM18.125 17.5V.25H.875V17.5h17.25ZM18.125 34.75H.875V52h17.25V34.75Z"
    />
    <path
      fill="#3C0061"
      fillRule="evenodd"
      d="M156.125.25h-86.25V17.5h69v86.25h-51.75v51.75h-69V69.25H.875v103.5h103.5V155.5h17.25v-17.25h17.25V121h17.25V.25Zm-34.5 138h-17.25V121h17.25v17.25Z"
      clipRule="evenodd"
    />
    <path fill="#FD5201" d="M35.375 34.75V52h17.25V34.75h-17.25Z" />
  </svg>
)
export default Error404
