const WaitListIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={38}
    fill="none"
    {...props}
  >
    <path
      fill="#FD5201"
      stroke="#FD5201"
      strokeWidth={0.5}
      d="M23.38 12.5h.25V9.75h-9.452v2.75h9.202Zm-8.952 6.25h-.25v2.75h9.452v-2.75h-9.202Zm2.468 15.824-.073-.074H3.488v-31h21.88V26.409l.427-.43 2.238-2.25.073-.073V.75H.75v36.5H19.557l-.423-.426-2.238-2.25ZM13.154 8.717l.175-.176-.175-.176-1.573-1.582-.178-.179-.177.179-2.393 2.405-1.274-1.28-.177-.179-.177.179L5.63 9.49l-.175.176.175.176 3.025 3.041.177.179.177-.179 4.144-4.166Zm0 9 .175-.176-.175-.176-1.573-1.582-.178-.178-.177.178-2.393 2.406-1.274-1.281-.177-.178-.177.178L5.63 18.49l-.175.176.175.176 3.025 3.041.177.178.177-.178 4.144-4.166Zm-1.928 7.066-2.393 2.405-1.274-1.28-.177-.178-.177.178L5.63 27.49l-.175.176.175.176 3.025 3.041.177.178.177-.178 4.144-4.166.175-.176-.175-.176-1.573-1.582-.178-.178-.177.178Zm20.951 1.934.175-.176-.175-.176-1.573-1.582-.177-.178-.178.177-7.988 8.015-2.953-2.952-.177-.177-.177.177-1.573 1.582-.175.176.174.176 4.704 4.747.177.179.178-.18 9.738-9.808Z"
    />
  </svg>
)
export default WaitListIcon
