const WaitListRightArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#FD5201"
      d="M4 9.834h6.667L7.75 12.75l1.183 1.184L13.867 9 8.933 4.067 7.75 5.25l2.917 2.917H4v1.667ZM17.333 9A8.333 8.333 0 1 1 .666 9a8.333 8.333 0 0 1 16.667 0Z"
    />
  </svg>
);
export default WaitListRightArrow;
