import frontMatter from "front-matter"
import { useEffect } from "react"
import { useState } from "react"

export const useParseMarkdown = (mdFilePath) => {
  const [content, setContent] = useState(() => "")
  const [frontmatter, setFrontmatter] = useState(() => "")

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const response = await fetch(mdFilePath)
        const markdownContent = await response.text()
        const { attributes, body } = frontMatter(markdownContent)
        setFrontmatter(attributes)
        setContent(body)
      } catch (err) {
        throw err
      }
    }

    fetchMarkdown()
  }, [mdFilePath])

  return {
    content,
    frontmatter,
  }
}