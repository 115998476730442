const Spinner = (props) => {
  return (
    <p
      className="spinning-loader"
      style={{
        top: props?.top ? `${props?.top}px` : null
      }}
    />
  )
}

export default Spinner