import { useState } from "react"
import { DownloadingLoader, PdfDownloading } from "../../components/PdfDownloading"
import mdFilePath from "../../content/prohibited-content-policy.md"
import { useParseMarkdown } from "../../hooks/useParseMarkdown"
import Markdown from "markdown-to-jsx"
import TocArrow from "../../components/svg/TocArrow"
import { SEO } from "../../components/meta/SEO"
import meta from "../../components/meta/meta.json"

const ProhibitedContentPolicy = ({ downloadBtn = true, isClone = false }) => {
  const { content, frontmatter } = useParseMarkdown(mdFilePath)
  const [downloading, setDownloading] = useState(() => false)

  return (
    <>
      <SEO
        title={meta?.prohibitedContentPolicy?.title}
        description={meta?.prohibitedContentPolicy?.description}
      />
      <DownloadingLoader downloading={downloading}
        component="prohibited-content-policy"
      />
      <div
        className={isClone ? "prohibited-content-policy clone-comp" : "prohibited-content-policy"}
        id={isClone ? "prohibited-content-policy" : null}
      >
        <h1 className="p-title">
          {frontmatter?.title}
        </h1>
        <div className="policy-subtitle download-pdf">
          <h3>
            {frontmatter?.subtitle}
          </h3>
          {downloadBtn &&
            <PdfDownloading
              setDownloading={setDownloading}
              policy="prohibited-content-policy"
            />
          }
        </div>
        <div className="effective-date">
          <time>
            {frontmatter?.date}
          </time>
        </div>
        <div className="prohibited-content-policy__content">
          <Markdown
            options={{
              overrides: {
                TocArrow: {
                  component: TocArrow
                }
              }
            }}
          >
            {content}
          </Markdown>
          <div className="effective-date">
            <time>
              {frontmatter?.date}
            </time>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProhibitedContentPolicy