import Obfuscate from "react-obfuscate"
import { Link } from "react-router-dom"

const InfoNote = ({
  para,
  email,
  paraLink = "",
  paraLink2 = "",
  href = "",
  linkText = "",
  isGenerationPage = false
}) => {
  return (
    <div className="info-note"
    style={{marginTop: isGenerationPage ? null : "3rem"}}
    >
      <div className="info-note__note">
        <p
          dangerouslySetInnerHTML={{
            __html: para,
          }}
        />
        <p style={{display: isGenerationPage ? "inline-block" : null}}>
          {paraLink}{" "}
          <Link to={href}>{linkText}</Link>{" "}
          {paraLink2}
        </p>
        {isGenerationPage && " "}
        <span
          onContextMenu={(e) => e.preventDefault()}
          onCopy={(e) => e.preventDefault()}
          onCut={(e) => e.preventDefault()}
        >
          <Obfuscate email={`${email}`} />
        </span>
      </div>
    </div>
  );
};

export default InfoNote;
