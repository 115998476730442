import { useEffect } from "react"
import { NavigationType, useLocation, useNavigationType } from "react-router-dom"

const useBackButton = () => {
  const navType = useNavigationType()
  return navType === NavigationType.Pop
}

export const useScrollToTop = () => {
  const { pathname } = useLocation()
  const isPop = useBackButton()

  const handleScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    })
  }

  useEffect(() => {
    handleScroll()
  }, [pathname, isPop])

  useEffect(() => {
    window.addEventListener("beforeunload", handleScroll)
    return () => {
      window.removeEventListener("beforeunload", handleScroll)
    }
  }, [])
}