import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import Spinner from "./Spinner"
import CloneComponent from "./CloneComponent"
import { debounce } from "debounce"

export const PdfDownloading = ({ setDownloading, policy }) => {

  const downloadPdf = debounce(() => {
    const data = document.getElementById(`${policy}`)

    html2canvas(data, { useCORS: true, allowTaint: true, scrollY: 0 }).then((canvas) => {
      const margin = [0.25, 0.25]
      const imgWidth = 7
      const imgHeight = (canvas.height * imgWidth) / canvas.width

      const innerPageWidth = imgWidth - margin[0] * 2
      const innerPageHeight = imgHeight - margin[1] * 2

      const doc = new jsPDF("p", "in", [imgWidth, imgHeight])
      doc.addImage(canvas, "PNG", margin[1], margin[0], innerPageWidth, innerPageHeight, undefined, "FAST")
      doc.save(`sonata-${policy}.pdf`)
      setDownloading(false)
      document.getElementById("pdf-btn").style.display = "block"
    })
  }, 800)

  return (
    <button
      type="button"
      className="pdf-download-btn"
      onClick={() => {
        document.getElementById("pdf-btn").style.display = "none"
        setDownloading(true)
        downloadPdf()
      }}
      id="pdf-btn"
      onContextMenu={(e) => e.preventDefault()}
      onCopy={(e) => e.preventDefault()}
      onCut={(e) => e.preventDefault()}
      style={{ userSelect: "none" }}
    >
      Download PDF
    </button>
  )
}

export const DownloadingLoader = ({ downloading, component }) => {

  return (
    <>
      {downloading &&
        <>
          <div className="downloading-pdf">
            <div>
              <Spinner />
              Downloading Pdf
            </div>
          </div>
          <CloneComponent component={component} />
        </>
      }
    </>
  )
}