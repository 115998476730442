const ToggleMenu = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M5.333 24h21.334C27.4 24 28 23.4 28 22.667c0-.734-.6-1.334-1.333-1.334H5.333c-.733 0-1.333.6-1.333 1.334C4 23.4 4.6 24 5.333 24Zm0-6.667h21.334c.733 0 1.333-.6 1.333-1.333s-.6-1.333-1.333-1.333H5.333C4.6 14.667 4 15.267 4 16s.6 1.333 1.333 1.333ZM4 9.333c0 .734.6 1.334 1.333 1.334h21.334c.733 0 1.333-.6 1.333-1.334C28 8.6 27.4 8 26.667 8H5.333C4.6 8 4 8.6 4 9.333Z"
    />
  </svg>
)
export default ToggleMenu
