const BigTick = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width ? props?.width : 176}
    height={props?.height ? props?.height : 175}
    viewBox="0 0 176 175"
    fill="none"
    {...props}
  >
    <path
      fill="#3C0061"
      d="M88 0a87.502 87.502 0 0 1 87.5 87.5 87.504 87.504 0 0 1-25.628 61.872 87.504 87.504 0 0 1-123.744 0 87.502 87.502 0 0 1 0-123.744A87.5 87.5 0 0 1 88 0ZM77.1 104.762l-19.437-19.45A7.504 7.504 0 0 0 47.05 95.925l24.75 24.75a7.474 7.474 0 0 0 10.612 0l51.251-51.263A7.5 7.5 0 1 0 123.062 58.8L77.1 104.762Z"
    />
  </svg>
)
export default BigTick
