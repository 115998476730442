const TickOrange = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width ? props?.width : 185}
    height={props?.height ? props?.height : 184}
    viewBox="0 0 185 184"
    fill="none"
    {...props}
  >
    <path
      stroke="#3C0061"
      strokeLinejoin="round"
      strokeWidth={16.667}
      d="M92.5 175.333a83.069 83.069 0 0 0 58.925-24.408A83.073 83.073 0 0 0 175.832 92a83.07 83.07 0 0 0-24.409-58.925A83.067 83.067 0 0 0 92.499 8.667a83.077 83.077 0 0 0-58.925 24.408A83.075 83.075 0 0 0 9.166 92a83.066 83.066 0 0 0 24.408 58.925A83.073 83.073 0 0 0 92.5 175.333Z"
    />
    <path
      stroke="#FD5201"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={16.667}
      d="m59.166 92 25 25 50-50"
    />
  </svg>
)
export default TickOrange
