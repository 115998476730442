const TocArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#3C0061"
      d="m10.313 17.517 7.7-7.7c.116-.117.199-.243.248-.38.048-.135.072-.281.072-.437 0-.155-.024-.301-.072-.437a1.03 1.03 0 0 0-.248-.38l-7.7-7.7A1.141 1.141 0 0 0 9.51.149a1.088 1.088 0 0 0-.832.336 1.092 1.092 0 0 0-.365.801c-.01.321.103.599.336.832l5.717 5.717H1.329c-.33 0-.608.111-.832.334A1.132 1.132 0 0 0 .163 9c0 .33.111.608.334.83.224.225.502.337.832.337h13.038L8.65 15.883a1.16 1.16 0 0 0-.336.817c-.01.33.103.603.336.817.214.233.486.35.817.35.33 0 .612-.117.845-.35Z"
    />
  </svg>
)
export default TocArrow
