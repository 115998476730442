import WaitListSuccess from "../svg/WaitListSuccess"

const SuccessModal = ({
  para1,
  para2 = "",
  styleBorder = ""
}) => {
  return (
    <div className="success-modal"
      style={{ border: styleBorder ? "1px solid #c6bee3" : null }}
    >
      <WaitListSuccess />
      <p className="success-modal__para1">
        {para1}
      </p>
      <p className="success-modal__para2">
        {para2}
      </p>
    </div>
  )
}

export default SuccessModal