import { Link } from "react-router-dom";
import LogoLarge from "../svg/LogoLarge";
import data from "./data.json"
import DiscordSmall from "../svg/DiscordSmall";
import { useWindowWidth } from "../../hooks/useWindowWidth";

const discordLink = process.env.REACT_APP_DISCORD_LINK

function Footer() {
  const currentYear = new Date().getFullYear()
  const screenWidth = useWindowWidth()

  return (
    <>
      <div className="footer-container">
        <div className="footer-container__inner">
          <div className="footer-links">
            <div className="logo">
              <Link to="/">
                <LogoLarge width={208} height={150} />
              </Link>
            </div>
            <div className="links"
              onContextMenu={(e) => e.preventDefault()}
              onCopy={(e) => e.preventDefault()}
              onCut={(e) => e.preventDefault()}
            >
              <a href={discordLink} target="_blank"
                className="footer-join-discord"
              >
                <span>{data?.discord?.joinUs}</span>
                <DiscordSmall />
              </a>
              {screenWidth <= 1080 ?
                <div>
                  {data?.footerLinks.map((el, i) => {
                    return (
                      <div className="footer-divs" key={i}>
                        <h5>{el?.heading}</h5>
                        <div>
                          {el.links.map((el, i) => {
                            return (
                              <Link
                                to={el?.slug}
                                key={i}
                              >
                                {el?.linkText}
                              </Link>
                            )
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
                :
                <>
                  {data?.footerLinks.map((el, i) => {
                    return (
                      <div className="footer-divs" key={i}>
                        <h5>{el?.heading}</h5>
                        <div>
                          {el.links.map((el, i) => {
                            return (
                              <Link
                                to={el?.slug}
                                key={i}
                              >
                                {el?.linkText}
                              </Link>
                            )
                          })}
                        </div>
                      </div>
                    );
                  })}
                </>
              }
            </div>
          </div>
          <div className="copyright">
            <p>
              <span>&#169;{" "}{currentYear}{" "}</span>
              {data?.copyrightText}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;