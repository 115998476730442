import "./sass/main.scss"
import { Route, Routes, useMatch } from "react-router-dom"
import { useState, useEffect } from "react"
import ContactUs from "./pages/ContactUs"
import DMCA from "./pages/DMCA"
import ReportAbuse from "./pages/ReportAbuse"
import Roadmap from "./pages/Roadmap"
import About from "./pages/About"
import GenerationSocialMedia from "./pages/generationSocialMedia/GenerationSocialMedia"
import SonataWhitePaper from "./pages/whitepaper/SonataWhitePaper"
import Header from "./components/header/Header"
import Footer from "./components/footer/Footer"
import PageNotFound from "./pages/404"
import Home from "./pages/home/Home"
import { useScrollToTop } from "./hooks/useScrollToTop"
import { useCookiesContext } from "./hooks/useCookiesContext"
import CookiesConsent from "./components/modal/CookiesConsent"
import PoliciesRouter from "./pages/policies/PoliciesRouter"
import ApiResponsesRouter from "./pages/apiResponses/ApiResponsesRouter"
import ReactGA from "react-ga4"
import PressRelease from "./pages/pressReleases/PressRelease"

const gaTagId = process.env.REACT_APP_GA_TAG_ID

function App() {
  useScrollToTop()
  const isHomePage = useMatch("/")
  const { cookiesState } = useCookiesContext()
  const [showComponent, setShowComponent] = useState(() => false)
  const isPolicies = useMatch("/policies/*")
  const isPressReleasePage = useMatch("/press-releases")
  const isArticles = useMatch("/third-generation-social-media")

  useEffect(() => {
    if (cookiesState) {
      ReactGA.initialize(gaTagId)
    }
  }, [cookiesState])

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowComponent(true)
    }, 1000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      <Header
        isHomePage={isHomePage?.pathname}
        isPolicyPages={isPolicies?.pathnameBase}
        isArticles={isArticles?.pathname}
      />
      <main
        style={{
          minHeight:
            isPolicies?.pathnameBase || isPressReleasePage?.pathnameBase ?
              "100vh"
              :
              null
        }}
      >
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/policies/*" element={<PoliciesRouter />} />
          {/* <Route path="/dmca" element={<DMCA />} /> */}
          {/* <Route path="/report-abuse" element={<ReportAbuse />} /> */}
          <Route path="/roadmap" element={<Roadmap />} />
          <Route
            path="/third-generation-social-media"
            element={<GenerationSocialMedia />}
          />
          <Route path="/whitepaper" element={<SonataWhitePaper />} />
          <Route path="/response/*" element={<ApiResponsesRouter />} />
          <Route path="/press-releases" element={<PressRelease />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </main>
      <Footer />

      {!cookiesState && showComponent && <CookiesConsent />}
    </>
  )
}

export default App
