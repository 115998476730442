import * as React from "react";
const BigPdfLeftArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 84}
    height={props.height ? props.height : 84}
    fill="none"
    viewBox="0 0 84 84"
    {...props}
  >
    <path
      stroke="#3C0061"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={8.333}
      d="M46.167 54.5 33.667 42l12.5-12.5"
    />
    <path
      stroke="#D9D4ED"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={8.333}
      d="M4.5 12.833A8.333 8.333 0 0 1 12.833 4.5h58.334a8.333 8.333 0 0 1 8.333 8.333v58.334a8.333 8.333 0 0 1-8.333 8.333H12.833A8.333 8.333 0 0 1 4.5 71.167V12.833Z"
    />
  </svg>
);
export default BigPdfLeftArrow;
