import { useEffect, useState } from "react"
import PdfViewer from "./PdfViewer";
import PdfViewerMbl from "./PdfViewerMbl";
import FullScreenIcon from "../../components/svg/FullScreenIcon"
import { isSafari, browserVersion } from "react-device-detect"
import { useWindowWidth } from "../../hooks/useWindowWidth"
import { SEO } from "../../components/meta/SEO"
import meta from "../../components/meta/meta.json"

const whitePaperFileName = process.env.REACT_APP_WHITEPAPER_FILENAME

const SonataWhitePaper = () => {
  const [parentWidth, setParentWidth] = useState(null)
  const [isBrowserSafari, setIsBrowserSafari] = useState(() => false)
  const screenWidth = useWindowWidth()

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = require(`../../assets/files/${whitePaperFileName}`)
    link.download = require(`../../assets/files/${whitePaperFileName}`)
    link.click();
  };

  // const handleFullScreen = () => {
  //   if (document.documentElement.requestFullscreen) {
  //     document.documentElement.requestFullscreen();
  //   } else if (document.documentElement.mozRequestFullScreen) {
  //     // Firefox
  //     document.documentElement.mozRequestFullScreen();
  //   } else if (document.documentElement.webkitRequestFullscreen) {
  //     // Chrome, Safari, Opera
  //     document.documentElement.webkitRequestFullscreen();
  //   } else if (document.documentElement.msRequestFullscreen) {
  //     // IE/Edge
  //     document.documentElement.msRequestFullscreen();
  //   }
  // };

  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleClick = () => {
    if (!isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    setIsFullScreen(!isFullScreen);
  };

  const handleParentResize = () => {
    const width = document.getElementById("parent-container").clientWidth;
    setParentWidth(width);
  };

  useEffect(() => {
    if (isSafari && browserVersion < 11.1) {
      setIsBrowserSafari(true)
    }
  }, [])

  return (
    <>
      <SEO
        title={meta?.whitepaper?.title}
        description={meta?.whitepaper?.description}
      />
      <div className="whitepaper-container">
        <div className="heading-box">
          <h1>Sonata Whitepaper</h1>
          <div className="download-btn" onClick={handleDownload}>
            Download PDF
          </div>
        </div>
        <div id="parent-container" ref={handleParentResize} className="pdf-box">
          <>
            <div className="pdf-items">
              <div className="fullscreen" onClick={handleClick}>
                <FullScreenIcon />
              </div>
            </div>
            {screenWidth <= 998 ?
              <PdfViewerMbl />
              :
              <PdfViewer
                parentWidth={parentWidth}
              />
            }
          </>
        </div>
      </div>
    </>
  );
};

export default SonataWhitePaper;
