import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import PdfLeftArrow from "../../components/svg/PdfLeftArrow";
import PdfRightArrow from "../../components/svg/PdfRightArrow";
import BigPdfLeftArrow from "../../components/svg/BigPdfLeftArrow";
import BigPdfRightArrow from "../../components/svg/BigPdfRightArrow";
import Select from "react-select";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.min.js`;

const whitePaperFileName = process.env.REACT_APP_WHITEPAPER_FILENAME

const style = {
  control: (base) => ({
    ...base,
    border: "2px solid #F3EEF9",
    boxShadow: "none",
    borderRadius: "8px",
    outline: "none",
    "&:hover": {
      border: "transparent solid #F3EEF9",
    },
    cursor: "pointer",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),

  container: (base, state) => ({
    ...base,
    width: "140px",
    border: "none",
    cursor: "pointer",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transform: state.selectProps.menuIsOpen && "rotate(180deg)",
    transition: "all .2s ease",
  }),
  menu: (base) => ({
    ...base,
    border: "2px solid #F3EEF9",
    borderRadius: "8px",
  }),
  indicatorContainer: (base) => ({
    ...base,
    padding: "3px 8.5px",
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "3px 8.5px",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? "transparent" : null,
    color: state.isSelected ? "black" : null,
    padding: "4px 12px",
    cursor: "pointer",

    "&:active": {
      backgroundColor: "transparent",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  }),
};

const PdfViewer = ({parentWidth, isBrowserSafari}) => {
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showCover, setShowCover] = useState(true);
  const [isDocLoaded, setIsDocLoaded] = useState(false);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setShowCover(false);
    }
  };

  const handleNextPage = () => {
    if (currentPage < numPages - 1) {
      setCurrentPage(currentPage + 1);
      setShowCover(false);
    } else if (currentPage === numPages - 1) {
      setCurrentPage(currentPage + 1);
      setShowCover(false);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setIsDocLoaded(true);
  };

  const DoublePage = ({ pageNumber }) => {
    const pageWidth = parentWidth / 2.03;
    const pageHeight = 842;
    return (
      <div className="pdf-pages">
        <Page
          pageNumber={pageNumber}
          width={pageWidth}
          height={pageHeight}
          renderAnnotationLayer={false}
          renderTextLayer={false}
        />
        {numPages === pageNumber && pageNumber % 2 !== 0 ? null : (
          <Page
            pageNumber={pageNumber + 1}
            width={pageWidth}
            height={pageHeight}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        )}
      </div>
    );
  };

  const handleCoverClick = () => {
    setShowCover(false);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (showCover) {
        if (e.keyCode === 37 || e.keyCode === 39) {
          handleCoverClick()
        }
      } else {
        if (e.keyCode === 37) {
          if (currentPage === 1) { } else { handlePreviousPage() }
        } else if (e.keyCode === 39) {
          if (currentPage === numPages - 1) { } else { handleNextPage() }
        }
      }
    }

    document.addEventListener("keydown", handleKeyDown)
    return () => document.removeEventListener("keydown", handleKeyDown)
  }, [showCover, currentPage, numPages])

  return (
    <div>
      {showCover && (
        // <div style={{ display: "flex" }} onClick={handleCoverClick}>
        <div className="cover-container">
          {isDocLoaded && (
            <div className="clickable" onClick={handleCoverClick}>
              <BigPdfLeftArrow />
            </div>
          )}

          <div className="cover">
            <Document
              file={require(`../../assets/files/${whitePaperFileName}`)}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                pageNumber={1}
                width={595}
                height={842}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            </Document>
          </div>
          {isDocLoaded && (
            <div className="clickable" onClick={handleCoverClick}>
              <BigPdfRightArrow />
            </div>
          )}
        </div>
      )}

      {!showCover && (
        <div className="pdf-pages-container">
          <Document
            file={require(`../../assets/files/${whitePaperFileName}`)}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <DoublePage pageNumber={currentPage} />
          </Document>
        </div>
      )}
      {isDocLoaded && (
        <div className="pdf-nav">
          {!showCover && (
            <div
              className="clickable"
              onClick={currentPage === 1 ? undefined : handlePreviousPage}
            >
              <PdfLeftArrow />
            </div>
          )}

          <div className="pdf-current">
            <div>Page:</div>
            <div className="dropdown-container">
              {showCover ? (
                `1`
              ) : (
                // <select
                //   value={currentPage}
                //   onChange={(e) => setCurrentPage(parseInt(e.target.value))}
                //   className="dropdown"
                // >
                //   {Array.from(Array(numPages).keys()).map((page) => (
                //     <option key={page + 1} value={page + 1}>
                //       {`${page + 1}-${page + 2}`}
                //     </option>
                //   ))}
                // </select>
                <Select
                  value={{
                    value: currentPage,
                    label:
                      numPages === currentPage && currentPage % 2 !== 0
                        ? currentPage
                        : `${currentPage}-${currentPage + 1}`,
                  }}
                  options={Array.from(
                    Array(Math.ceil(numPages / 2)).keys()
                  ).map((page) => {
                    const optionValue = page * 2 + 1;
                    if (optionValue === numPages) {
                      return {
                        value: optionValue,
                        label: `${optionValue}`,
                      };
                    }
                    return {
                      value: optionValue,
                      label: `${optionValue}-${optionValue + 1}`,
                    };
                  })}
                  onChange={(selectedOption) =>
                    setCurrentPage(selectedOption.value)
                  }
                  className="dropdown"
                  styles={style}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                />
              )}
            </div>
            <div>/{numPages}</div>
          </div>
          {!showCover && (
            <div
              onClick={
                currentPage === numPages - 1 ? undefined : handleNextPage
              }
              className="clickable"
            >
              <PdfRightArrow />
            </div>
          )}
        </div>
      )}

      {/* {isLoading && showLoader} */}

    </div>
  );
};

export default PdfViewer;
