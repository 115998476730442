const DiscordLarge = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={176}
    height={134}
    fill="none"
    {...props}
  >
    <path
      fill={props?.color ? props?.color : "#fff"}
      d="M148.583 11.417C137.5 6.25 125.5 2.5 113 .333a.745.745 0 0 0-.583.25c-1.5 2.75-3.25 6.334-4.417 9.084-13.258-2-26.742-2-40 0-1.167-2.834-2.917-6.334-4.5-9.084-.083-.166-.333-.25-.583-.25a142.597 142.597 0 0 0-35.584 11.084c-.083 0-.166.083-.25.166C4.417 45.5-1.833 78.5 1.25 111.167c0 .166.083.333.25.416 15 11 29.417 17.667 43.667 22.084.25.083.5 0 .583-.167 3.333-4.583 6.333-9.417 8.917-14.5.166-.333 0-.667-.334-.75-4.75-1.833-9.25-4-13.666-6.5-.334-.167-.334-.667-.084-.917.917-.666 1.834-1.416 2.75-2.083a.512.512 0 0 1 .584-.083c28.666 13.083 59.583 13.083 87.916 0a.513.513 0 0 1 .584.083c.916.75 1.833 1.417 2.75 2.167.333.25.333.75-.084.916-4.333 2.584-8.916 4.667-13.666 6.5-.334.084-.417.5-.334.75a127.386 127.386 0 0 0 8.917 14.5c.25.084.5.167.75.084 14.333-4.417 28.75-11.084 43.75-22.084a.457.457 0 0 0 .25-.416c3.667-37.75-6.083-70.5-25.833-99.584-.084-.083-.167-.166-.334-.166ZM59 91.25c-8.583 0-15.75-7.917-15.75-17.667 0-9.75 7-17.666 15.75-17.666 8.833 0 15.833 8 15.75 17.666 0 9.75-7 17.667-15.75 17.667Zm58.083 0c-8.583 0-15.75-7.917-15.75-17.667 0-9.75 7-17.666 15.75-17.666 8.834 0 15.834 8 15.75 17.666 0 9.75-6.916 17.667-15.75 17.667Z"
    />
  </svg>
)
export default DiscordLarge
