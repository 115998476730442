import data from "../languages/english.json";
import { Formik, useFormik } from "formik";
import { contactUsSchema } from "../validationSchema/index";
import { useState } from "react";
import axios from "axios"
import { encode } from "base-64"
import SuccessModal from "../components/modal/SuccessModal";
import DangerAlert from "../components/svg/DangerAlert";
import Headphones from "../components/svg/Headphones"
import { SEO } from "../components/meta/SEO"
import meta from "../components/meta/meta.json"

const contactApiEndPoint = process.env.REACT_APP_CONTACT_API_ENDPOINT

export default function ContactUs() {
  // const [detail, setDetail] = useState({})
  const [disable, setDisable] = useState(false)
  const form = data?.contactUs?.form;
  const [successModal, setSuccessModal] = useState(() => false)
  const [apiRes, setApiRes] = useState(() => "")

  const initialValues = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    isValid,
    resetForm,
    isSubmitting
  } =
    useFormik({
      initialValues,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: contactUsSchema,
      onSubmit: async (values, action) => {
        setDisable(true)
        let encodedEmail = encode(values?.email)
        await axios({
          method: "POST",
          url: contactApiEndPoint,
          data: {
            request_type: "contact_us",
            ...values,
            email: encodedEmail
          },
          headers: {
            "Content-Type": "text/plain",
          }
        }).then((res) => {
          action.resetForm()
          setSuccessModal(true)
          setDisable(false)
          setApiRes("")
          setTimeout(() => {
            setSuccessModal(false)
          }, 2000)
        }).catch((err) => {
          setApiRes(err?.response?.data?.msg)
          setDisable(false)
        })
      },
    });

  let error = Object.keys(errors)

  const cancelForm = () => {
    resetForm()
  }

  return (
    <>
      <SEO
        title={meta?.contact?.title}
        description={meta?.contact?.description}
      />
      <div className="contactus-container">
        <div>
          <div className="form-head">
            <Headphones />
            <h4>{form.name}</h4>
          </div>
          <div className="form-body">
            <label htmlFor="name">{form.fields.name.label}</label>
            <input
              type="text"
              placeholder={form.fields.name.placeholder}
              id="name"
              name="name"
              className="custom-input"
              value={values.name}
              onChange={handleChange}
            // onBlur={handleBlur}
            />
            <div className="form-field-error">
              <small>
                {/* {(isSubmitting && errors?.name) && errors?.name} */}
                {errors?.name && errors?.name}
              </small>
            </div>
          </div>
          <div className="form-body">
            <label htmlFor="email">{form.fields.email.label}</label>
            <input
              type="email"
              placeholder={form.fields.email.placeholder}
              id="email"
              name="email"
              className="custom-input"
              value={values.email}
              onChange={handleChange}
            // onBlur={handleBlur}
            />
            <div className="form-field-error">
              <small>
                {/* {(isSubmitting && errors?.email) && errors?.email} */}
                {errors?.email && errors?.email}
              </small>
            </div>
          </div>
          <div className="form-body">
            <label htmlFor="subject">{form.fields.subject.label}</label>
            <input
              type="text"
              placeholder={form.fields.subject.placeholder}
              id="subject"
              name="subject"
              className="custom-input"
              value={values.subject}
              onChange={handleChange}
            // onBlur={handleBlur}
            />
            <div className="form-field-error">
              <small>
                {/* {(isSubmitting && errors?.subject) && errors?.subject} */}
                {errors?.subject && errors?.subject}
              </small>
            </div>
          </div>
          <div className="form-body">
            <label htmlFor="message">{form.fields.message.label}</label>
            <textarea
              name="message"
              id="message"
              cols="30"
              rows="7"
              className="custom-input"
              placeholder={form.fields.message.placeholder}
              value={values.message}
              onChange={handleChange}
            // onBlur={handleBlur}
            />
            <div className="form-field-error">
              <small>
                {/* {(isSubmitting && errors?.message) && errors?.message} */}
                {errors?.message && errors?.message}
              </small>
            </div>
          </div>
          <div className="error-messages">
            {
              apiRes ?
                <small>
                  <>
                    <DangerAlert />
                    {
                      apiRes && apiRes
                    }
                  </>
                </small> : null
            }
          </div>
          <div className="form-footer"
            onContextMenu={(e) => e.preventDefault()}
            onCopy={(e) => e.preventDefault()}
            onCut={(e) => e.preventDefault()}
          >
            <div className="footer-button">
              <button type="button" className="custom-transparent" onClick={cancelForm}
                disabled={isSubmitting}
              >
                {form.fields.buttons.cancel}
              </button>
            </div>
            <div className="footer-button">
              <button
                type="button"
                className="custom-button"
                onClick={handleSubmit}
                // disabled={disable}
                disabled={isSubmitting}
              >
                {form.fields.buttons.submit}
              </button>
            </div>
          </div>
        </div>

        {successModal && (
          <SuccessModal
            para1={"Email Sent"}
            styleBorder={true}
          />
        )}
      </div>
    </>
  );
}
