import Error404 from "../components/svg/Error404"
import { useWindowWidth } from "../hooks/useWindowWidth"
import CustomMessages from "../components/customMessages/CustomMessage"
import data from "../languages/english.json"

const PageNotFound = () => {
  const screenWidth = useWindowWidth()

  return (
    <CustomMessages
      icon={
        screenWidth <= 767 ?
          <Error404 width={90} height={100} />
          :
          <Error404 />
      }
      data={data?.pageNotFound}
      isPageNotFound={true}
    />
  )
}

export default PageNotFound