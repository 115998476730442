const HashIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width ? props?.width : 52}
    height={props?.height ? props?.height : 52}
    viewBox="0 0 52 52"
    fill="none"
    {...props}
  >
    <path
      fill="#FD5201"
      d="M49.111 30.815H36.593V20.03a10.51 10.51 0 0 0 4.814 1.155C47.253 21.185 52 16.438 52 10.593 52 4.747 47.253 0 41.407 0c-5.845 0-10.592 4.747-10.592 10.593v4.814h-9.63V2.89A2.885 2.885 0 0 0 18.296 0a2.885 2.885 0 0 0-2.889 2.889v12.518H2.89A2.885 2.885 0 0 0 0 18.297a2.885 2.885 0 0 0 2.889 2.888h12.518V31.97a10.51 10.51 0 0 0-4.814-1.155C4.747 30.815 0 35.562 0 41.407 0 47.253 4.747 52 10.593 52c5.845 0 10.592-4.747 10.592-10.593v-4.814h9.63V49.11A2.885 2.885 0 0 0 33.704 52a2.885 2.885 0 0 0 2.889-2.889V36.593H49.11A2.885 2.885 0 0 0 52 33.703a2.885 2.885 0 0 0-2.889-2.888ZM41.407 5.778a4.817 4.817 0 0 1 4.815 4.815 4.817 4.817 0 0 1-4.815 4.814 4.817 4.817 0 0 1-4.814-4.814 4.817 4.817 0 0 1 4.814-4.815ZM10.593 46.222a4.817 4.817 0 0 1-4.815-4.815 4.817 4.817 0 0 1 4.815-4.814 4.817 4.817 0 0 1 4.814 4.814 4.817 4.817 0 0 1-4.814 4.815Zm10.592-15.407v-9.63h9.63v9.63h-9.63Z"
    />
  </svg>
)
export default HashIcon
