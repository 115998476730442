import * as React from "react";
const BigPdfRightArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 84}
    height={props.height ? props.height : 84}
    fill="none"
    viewBox="0 0 84 84"
    {...props}
  >
    <path
      stroke="#3C0061"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={8.333}
      d="m37.833 54.5 12.5-12.5-12.5-12.5"
    />
    <path
      stroke="#D9D4ED"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={8.333}
      d="M79.5 12.833A8.333 8.333 0 0 0 71.167 4.5H12.833A8.333 8.333 0 0 0 4.5 12.833v58.334a8.333 8.333 0 0 0 8.333 8.333h58.334a8.333 8.333 0 0 0 8.333-8.333V12.833Z"
    />
  </svg>
);
export default BigPdfRightArrow;
