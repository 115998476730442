import data from "../languages/english.json"
import InfoNote from "../components/InfoNote"
import Markdown from "markdown-to-jsx"
import { useWindowWidth } from "../hooks/useWindowWidth"
import HashIcon from "../components/svg/HashIcon"
import { useParseMarkdown } from "../hooks/useParseMarkdown"
import mdFilePath from "../content/about.md"
import LogoLarge from "../components/svg/LogoLarge"
import { SEO } from "../components/meta/SEO"
import meta from "../components/meta/meta.json"

export default function About() {
  const screenWidth = useWindowWidth()
  const { content, frontmatter } = useParseMarkdown(mdFilePath)

  return (
    <>
      <SEO
        title={meta?.about?.title}
        description={meta?.about?.description}
      />
      <div className="about-page">
        <div className="about-page__cover">
          <p>
            {screenWidth <= 1000 ?
              <LogoLarge width={190} height={145} />
              :
              <LogoLarge />
            }
          </p>
        </div>
        <p className="tagline">
          <span dangerouslySetInnerHTML={{ __html: frontmatter?.tagline }} />
        </p>
        <p className="sonata-icon">
          {screenWidth <= 767 ?
            <HashIcon width={32} height={32} />
            :
            <HashIcon />
          }
        </p>
        <div className="about-page__content">
          <Markdown>
            {content}
          </Markdown>
        </div>
        <InfoNote
          para={data?.infoNote?.infoNoteAbout.para}
          paraLink={data?.infoNote?.infoNoteAbout?.para2?.line1}
          paraLink2={data?.infoNote?.infoNoteAbout?.para2?.line2}
          href={data?.infoNote?.infoNoteAbout?.link?.href}
          linkText={data?.infoNote?.infoNoteAbout?.link?.text}
          email={data?.infoNote?.infoNoteAbout.email}
        />
      </div>
    </>
  )
}
