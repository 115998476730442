import { createContext, useEffect, useReducer } from "react";

export const CookiesContext = createContext();

export const CookiesReducer = (state, action) => {
  switch (action.type) {
    case "COOKIES_CONTEXT":
      return { cookiesState: action.payload.cookiesState };
    default:
      return state;
  }
};

export const CookiesContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CookiesReducer, {
    cookiesState: null,
  });

  useEffect(() => {
    const cookiesState = localStorage.getItem("cookies_consent");
    if (cookiesState) {
      dispatch({
        type: "COOKIES_CONTEXT",
        payload: { cookiesState: JSON.parse(cookiesState) },
      });
    }
  }, []);

  // console.log("CookiesContext state", state)

  return (
    <CookiesContext.Provider value={{ ...state, dispatch }}>
      {children}
    </CookiesContext.Provider>
  );
};
