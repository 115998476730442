import { useState } from "react";
import {
  DownloadingLoader,
  PdfDownloading,
} from "../../components/PdfDownloading";
import mdFilePath from "../../content/sonata-amplification-policy.md";
import { useParseMarkdown } from "../../hooks/useParseMarkdown";
import Markdown from "markdown-to-jsx";
import TocArrow from "../../components/svg/TocArrow";
import { SEO } from "../../components/meta/SEO"
import meta from "../../components/meta/meta.json"

const SonataAmplificationPolicy = ({ downloadBtn = true, isClone = false }) => {
  const { content, frontmatter } = useParseMarkdown(mdFilePath);
  const [downloading, setDownloading] = useState(() => false);

  return (
    <>
      <SEO
        title={meta?.amplificationPolicy?.title}
        description={meta?.amplificationPolicy?.description}
      />
      <DownloadingLoader downloading={downloading}
        component="sonata-amplification-policy"
      />
      <div
        className={isClone ? "sonata-amplification-policy clone-comp" : "sonata-amplification-policy"}
        id={isClone ? "sonata-amplification-policy" : null}
      >
        <h1 className="p-title">{frontmatter?.title}</h1>
        <div className="policy-subtitle download-pdf">
          <h3>{frontmatter?.subtitle}</h3>
          {downloadBtn &&
            <PdfDownloading
              setDownloading={setDownloading}
              policy="sonata-amplification-policy"
            />
          }
        </div>
        <div className="effective-date">
          <time>{frontmatter?.date}</time>
        </div>
        <div className="sonata-amplification-policy__content">
          <Markdown
            options={{
              overrides: {
                TocArrow: {
                  component: TocArrow,
                },
              },
            }}
          >
            {content}
          </Markdown>
        </div>
      </div>
    </>
  );
};

export default SonataAmplificationPolicy;
