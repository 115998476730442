const SearchIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width ? props?.width : 40}
    height={props?.height ? props?.height : 40}
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      fill="#3C0061"
      d="M7.5 21.25h10v2.5h-10v-2.5ZM3.75 23.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM16.25 18.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM2.5 16.25h10v2.5h-10v-2.5Zm5-5h10v2.5h-10v-2.5ZM3.75 13.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z"
    />
    <path
      fill="#3C0061"
      d="m37.5 35.75-9.25-9.25c1.875-2.5 3-5.625 3-9 0-8.25-6.75-15-15-15-4.125 0-8 1.625-10.875 4.75L7.25 9c2.25-2.625 5.5-4 9-4 6.875 0 12.5 5.625 12.5 12.5S23.125 30 16.25 30C12.5 30 9 28.375 6.625 25.5L4.75 27.125c2.75 3.375 7 5.375 11.5 5.375 4 0 7.625-1.625 10.375-4.125L35.75 37.5l1.75-1.75Z"
    />
  </svg>
);
export default SearchIcon;
