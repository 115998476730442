import { useEffect, useState } from "react"
import {
  bottomContent
} from "./PressReleaseBottomContent"
import axios from "axios"

const PressReleaseBottom = ({
  isBottomContent,
  pressBottomContent,
  bottomContentKey
}) => {
  const [img1, setImg1] = useState(() => null)
  const [img2, setImg2] = useState(() => null)
  const [downloadAsset1, setDownloadAsset1] = useState(() => null)
  const [downloadAsset2, setDownloadAsset2] = useState(() => null)
  const [downloadAsset1Info, setDownloadAsset1Info] = useState(() => null)
  const [downloadAsset2Info, setDownloadAsset2Info] = useState(() => null)

  useEffect(() => {
    setImg1(bottomContent?.[bottomContentKey]?.img1)
    setImg2(bottomContent?.[bottomContentKey]?.img2)
    setDownloadAsset1(bottomContent?.[bottomContentKey]?.asset1)
    setDownloadAsset2(bottomContent?.[bottomContentKey]?.asset2)
  }, [bottomContentKey])

  useEffect(() => {
    const handleAsset1Info = async (file) => {
      if (file) {
        try {
          const response = await axios.head(file)
          const fileSize = response?.headers["content-length"]
          const fileType = response?.headers["content-type"]
          if (fileSize && fileType) {
            const sizeInMb = fileSize / (1024 * 1024)
            const typeName = fileType.split("/").pop()
            setDownloadAsset1Info(`${sizeInMb.toFixed(1)} MB ${typeName} File`)
          } else {
            setDownloadAsset1Info(null)
          }
        } catch (err) {
          throw err
        }
      }
    }

    handleAsset1Info(downloadAsset1)
  }, [downloadAsset1])

  useEffect(() => {
    const handleAsset2Info = async (file) => {
      if (file) {
        try {
          const response = await axios.head(file)
          const fileSize = response?.headers["content-length"]
          const fileType = response?.headers["content-type"]
          if (fileSize && fileType) {
            const sizeInMb = fileSize / (1024 * 1024)
            const typeName = fileType.split("/").pop()
            setDownloadAsset2Info(`${sizeInMb.toFixed(1)} MB ${typeName} File`)
          } else {
            setDownloadAsset2Info(null)
          }
        } catch (err) {
          throw err
        }
      }
    }

    handleAsset2Info(downloadAsset2)
  }, [downloadAsset2])

  const handleAssetDownload = (file, filename) => {
    const link = document.createElement("a")
    link.href = file
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const PressBottom = ({
    downloadAsset,
    assetName,
    assetInfo,
    btnText,
    image
  }) => {

    return (
      <p>
        <button
          onClick={() => handleAssetDownload(downloadAsset, assetName)}
          style={{ gap: assetInfo ? "4rem" : null }}
        >
          <span>{btnText}</span>
          <span>
            {assetInfo && assetInfo}
          </span>
        </button>
        <img src={image} alt="" />
      </p>
    )
  }

  return isBottomContent && (
    <div className="press-release__bottom">
      <PressBottom
        downloadAsset={downloadAsset1}
        assetName={pressBottomContent?.downloadableAssetFilename1}
        assetInfo={downloadAsset1Info}
        btnText={pressBottomContent?.btn1Text}
        image={img1}
      />
      <PressBottom
        downloadAsset={downloadAsset2}
        assetName={pressBottomContent?.downloadableAssetFilename2}
        assetInfo={downloadAsset2Info}
        btnText={pressBottomContent?.btn2Text}
        image={img2}
      />
    </div>
  )
}

export default PressReleaseBottom