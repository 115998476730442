import { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import HandlePolicy from "../pages/policies/HandlePolicy"
import AcceptableUsePolicy from "../pages/policies/AcceptableUsePolicy"
import CookiesPolicy from "../pages/policies/CookiesPolicy"
import PrivacyPolicy from "../pages/policies/PrivacyPolicy"
import TermsOfService from "../pages/policies/TermsOfService"
import ProhibitedContentPolicy from "../pages/policies/ProhibitedContentPolicy"
import SonataAmplificationPolicy from "../pages/policies/SonataAmplificationPolicy"
import SonataCompanyPolicy from "../pages/policies/SonataCompanyPolicies"
import PrivacyInfoBreakdown from "../pages/policies/PrivacyInfoBreakdown"

const CloneComponent = ({ component }) => {
  const [comp, setComp] = useState(() => null)

  useEffect(() => {
    switch (component) {

      case "handle-policy":
        return setComp(<HandlePolicy downloadBtn={false} isClone={true} />)

      case "acceptable-use-policy":
        return setComp(<AcceptableUsePolicy downloadBtn={false} isClone={true} />)

      case "cookie-policy-sonata":
        return setComp(<CookiesPolicy downloadBtn={false} isClone={true} />)

      case "privacy-policy":
        return setComp(<PrivacyPolicy downloadBtn={false} isClone={true} />)

      case "terms-of-service":
        return setComp(<TermsOfService downloadBtn={false} isClone={true} />)

      case "prohibited-content-policy":
        return setComp(<ProhibitedContentPolicy downloadBtn={false} isClone={true} />)

      case "sonata-amplification-policy":
        return setComp(<SonataAmplificationPolicy downloadBtn={false} isClone={true} />)

      case "sonata-company-policy":
        return setComp(<SonataCompanyPolicy downloadBtn={false} isClone={true} />)

      case "privacy-info-breakdown":
        return setComp(<PrivacyInfoBreakdown downloadBtn={false} isClone={true} />)

      default:
        return setComp(null)
    }
  }, [component])

  return createPortal(
    <>
      {comp && comp}
    </>,
    document.getElementById("cloned-component")
  )
}

export default CloneComponent