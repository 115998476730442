import mdFilePath from "../../content/handle-policy.md"
import { useParseMarkdown } from "../../hooks/useParseMarkdown"
import Markdown from "markdown-to-jsx"
import { DownloadingLoader, PdfDownloading } from "../../components/PdfDownloading"
import { useState } from "react"
import { SEO } from "../../components/meta/SEO"
import meta from "../../components/meta/meta.json"

const HandlePolicy = ({ downloadBtn = true, isClone = false }) => {
  const { content, frontmatter } = useParseMarkdown(mdFilePath)
  const [downloading, setDownloading] = useState(() => false)

  return (
    <>
      <SEO
        title={meta?.handlePolicy?.title}
        description={meta?.handlePolicy?.description}
      />
      <DownloadingLoader downloading={downloading} component="handle-policy" />
      <div
        className={isClone ? "handle-policy clone-comp" : "handle-policy"}
        id={isClone ? "handle-policy" : null}
      >
        <h1 className="p-title download-pdf">
          {frontmatter?.title}
          {downloadBtn &&
            <PdfDownloading
              setDownloading={setDownloading}
              policy="handle-policy"
            />
          }
        </h1>
        <div className="effective-date">
          <time>
            {frontmatter?.date}
          </time>
        </div>
        <div className="handle-policy__content">
          <Markdown>
            {content}
          </Markdown>
        </div>
      </div>
    </>
  )
}

export default HandlePolicy