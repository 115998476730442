import CrossIcon from "../svg/CrossIcon"
import CookiesIcon from "../svg/CookiesIcon"
import { useEffect, useState, useReducer } from "react"
import { useCookiesContext } from "../../hooks/useCookiesContext"
import data from "./data.json"
import { Link } from "react-router-dom"

function reducer(state, action) {
  switch (action.type) {
    case "acceptCookies":
      return { cookiesAccepted: true }
    case "declineCookies":
      return { cookiesAccepted: false }
    default:
      return state
  }
}

const CookiesConsent = () => {
  const [isActive, setIsActive] = useState(true)
  const [state, dispatchSetCookie] = useReducer(reducer, {
    cookiesAccepted: localStorage.getItem("cookies_consent") === "true"
  })
  const { dispatch } = useCookiesContext()

  const createCookiesContext = () => {
    localStorage.setItem("cookies_consent", JSON.stringify(state.cookiesAccepted))
    dispatch({
      type: "COOKIES_CONTEXT",
      payload: { cookiesState: state.cookiesAccepted },
    })
  }

  useEffect(() => {
    createCookiesContext()
  }, [state])

  return (
    <>
      {isActive && (
        <div className="cookies-consent-container">
          <div className="cookies-consent">
            <header className="cookies-consent__header">
              <div className="cookies-consent__header__text">
                <span>
                  <CookiesIcon />
                </span>
                <h2>{data?.cookiesConsent?.heading}</h2>
              </div>
              <div className="cookies-consent__header__close">
                <span onClick={() => setIsActive(false)}><CrossIcon /></span>
              </div>
            </header>
            <section className="cookies-consent__para">
              <p>
                {data?.cookiesConsent?.para?.line1}
                {" "}
                <Link to={data?.cookiesConsent?.links?.privacy?.href}
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsActive(false)
                  }}
                >
                  {data?.cookiesConsent?.links?.privacy?.link}
                </Link>
                {" "}
                {data?.cookiesConsent?.para?.line2}
                {" "}
                <Link to={data?.cookiesConsent?.links?.cookies?.href}
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsActive(false)
                  }}
                >
                  {data?.cookiesConsent?.links?.cookies?.link}
                </Link>
                {"."}
              </p>
            </section>
            <footer className="cookies-consent__footer">
              <div className="cookies-consent__footer__cancel">
                <span onClick={() => {
                  dispatchSetCookie({ type: "declineCookies" })
                  setIsActive(false)
                }}
                >
                  {data?.cookiesConsent?.decline}
                </span>
              </div>
              <div className="cookies-consent__footer__save">
                <span className="button-bg-purple" onClick={() => {
                  dispatchSetCookie({ type: "acceptCookies" })
                  setIsActive(false)
                }}
                >
                  {data?.cookiesConsent?.accept}
                </span>
              </div>
            </footer>
          </div>
        </div>
      )}
    </>
  )
}

export default CookiesConsent