import data from "../../languages/english.json"
import InfoNote from "../../components/InfoNote"
import mdFilePath from "../../content/generation-social-media.md"
import { useParseMarkdown } from "../../hooks/useParseMarkdown"
import Markdown from "markdown-to-jsx"
import {
  GenerationDiscovery,
  GenerationOpposition,
  GenerationAttention,
  GenSearchIcon,
  GenAttentionIcon,
  GenOppositionIcon,
  GenResponsibilityIcon
} from "./GenerationComponents"
import { SEO } from "../../components/meta/SEO"
import meta from "../../components/meta/meta.json"

const GenerationSocialMedia = () => {
  const { content, frontmatter } = useParseMarkdown(mdFilePath)

  return (
    <>
      <SEO
        title={meta?.genSocialMedia?.title}
        description={meta?.genSocialMedia?.description}
      />
      <div className="third-gen-social-media">
        <div className="third-gen-social-media__title">
          <h1>
            {frontmatter?.title}
          </h1>
        </div>
        <div className="policy-subtitle download-pdf">
          <h3>
            {frontmatter?.subtitle}
          </h3>
        </div>
        <div className="effective-date">
          <time>
            {frontmatter?.date}
          </time>
        </div>
        <div className="third-gen-social-media__content">
          <Markdown
            options={{
              overrides: {
                GenSearchIcon,
                GenAttentionIcon,
                GenOppositionIcon,
                GenerationDiscovery,
                GenerationAttention,
                GenerationOpposition,
                GenResponsibilityIcon
              }
            }}
          >
            {content}
          </Markdown>
        </div>
      </div>

      <InfoNote
        para={data?.infoNote?.generationNote?.para}
        email={data?.infoNote?.generationNote?.email}
        paraLink={data?.infoNote?.generationNote?.para2?.line1}
        paraLink2={data?.infoNote?.generationNote?.para2?.line2}
        href={data?.infoNote?.generationNote?.link?.href}
        linkText={data?.infoNote?.generationNote?.link?.text}
        isGenerationPage={true}
      />
    </>
  );
};

export default GenerationSocialMedia;
