const Envelop = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width ? props?.width : 201}
    height={props?.height ? props?.height : 166}
    viewBox="0 0 201 166"
    fill="none"
    {...props}
  >
    <path
      stroke="#3C0061"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={16}
      d="M179.071 8H21.928c-7.89 0-14.285 6.396-14.285 14.286v121.428c0 7.89 6.396 14.286 14.285 14.286h157.143c7.89 0 14.286-6.396 14.286-14.286V22.286c0-7.89-6.396-14.286-14.286-14.286Z"
    />
    <path
      stroke="#3C0061"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={16}
      d="m7.643 25.857 83.714 71.429a14.286 14.286 0 0 0 18.286 0l83.714-71.429"
    />
  </svg>
)
export default Envelop
