import CustomMessages from "../../components/customMessages/CustomMessage"
import CodeExpiredIcon from "../../components/svg/CodeExpiredIcon"
import data from "./data.json"
import { useWindowWidth } from "../../hooks/useWindowWidth"

const CodeExpired = () => {
  const screenWidth = useWindowWidth()

  return (
    <CustomMessages
      icon={
        screenWidth <= 767 ?
          <CodeExpiredIcon width={90} height={100} />
          :
          <CodeExpiredIcon />
      }
      data={data?.codeExpired}
    />
  )
}

export default CodeExpired