import WaitListRightArrow from "../svg/WaitListRightArrow"
import data from "./data.json"

const JoinWaitlistAlert = ({handleWaitListModal}) => {
  return (
    <div className="waitlist-alert-container">
      <div className="waitlist-alert-container__inner">
        <div className="wait-list-text">
          {data?.waitList?.para}
        </div>
        <div
          className="waitlist-button"
          onClick={handleWaitListModal}
          onContextMenu={(e) => e.preventDefault()}
          onCopy={(e) => e.preventDefault()}
          onCut={(e) => e.preventDefault()}
        >
          <div>{data?.waitList?.btnText}</div>
          <span>
            <WaitListRightArrow />
          </span>
        </div>
      </div>
    </div>
  )
}

export default JoinWaitlistAlert