import { Helmet } from "react-helmet-async"

export const SEO = ({ title, description }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta name="google-adsense-account" content="ca-pub-1750856239204414" />
    </Helmet>
  )
}
