const LogoLarge = (props) => (
  <svg
    width={props?.width ? props?.width : "614"}
    height={props?.height ? props?.height : "468"}
    viewBox="0 0 614 468"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_323_382)">
      <path d="M66.3669 383.758C61.9867 381.742 57.2612 380.175 52.1903 379.057C47.1134 377.945 42.3939 376.828 38.0137 375.71C33.6334 374.599 30.0227 373.135 27.1752 371.319C24.3278 369.509 22.904 366.927 22.904 363.586C22.904 360.798 23.7401 358.436 25.4062 356.48C27.0722 354.53 29.2654 353.03 31.9735 351.985C34.6816 350.94 37.638 350.418 40.8369 350.418C45.5624 350.418 49.87 351.329 53.7655 353.133C57.655 354.943 60.7145 358.083 62.9379 362.536L78.7806 353.546C76.5572 349.646 73.6734 346.123 70.1292 342.989C66.5851 339.855 62.3442 337.383 57.4126 335.567C52.475 333.757 46.8104 332.852 40.4188 332.852C33.0518 332.852 26.4845 334.212 20.7169 336.927C14.9494 339.642 10.4298 343.341 7.16432 348.006C3.89885 352.677 2.26309 358.004 2.26309 363.993C2.26309 370.408 3.6868 375.492 6.53425 379.251C9.38169 383.011 12.9986 385.939 17.3727 388.028C21.7529 390.118 26.4724 391.685 31.5494 392.73C36.6202 393.775 41.3458 394.819 45.726 395.864C50.1062 396.909 53.717 398.342 56.5645 400.152C59.4119 401.962 60.8356 404.684 60.8356 408.304C60.8356 411.231 59.9329 413.74 58.1275 415.829C56.3221 417.919 53.8867 419.486 50.8332 420.531C47.7737 421.576 44.2296 422.098 40.2007 422.098C34.4998 422.098 29.5986 421.053 25.5031 418.964C21.4016 416.874 18.0331 413.254 15.3916 408.091L-0.45108 416.868C3.43841 424.534 8.96366 430.243 16.1247 434.009C23.2797 437.769 31.5857 439.652 41.0368 439.652C48.2645 439.652 54.9711 438.358 61.1567 435.783C67.3423 433.207 72.2739 429.514 75.9574 424.704C79.6409 419.899 81.4826 414.287 81.4826 407.879C81.4826 401.331 80.0529 396.174 77.2115 392.408C74.364 388.642 70.7472 385.757 66.373 383.734L66.3669 383.758Z" fill="white" />
      <path d="M166.542 339.655C158.339 334.851 149.1 332.445 138.813 332.445C128.526 332.445 119.281 334.851 111.084 339.655C102.881 344.46 96.3499 350.911 91.485 358.989C86.6201 367.074 84.1907 376.063 84.1907 385.952C84.1907 395.84 86.6201 404.867 91.485 413.018C96.3499 421.169 102.881 427.687 111.084 432.564C119.281 437.442 128.526 439.877 138.813 439.877C148.955 439.877 158.164 437.436 166.439 432.564C174.709 427.687 181.204 421.169 185.935 413.018C190.661 404.867 193.024 395.847 193.024 385.952C193.024 376.197 190.661 367.244 185.935 359.092C181.21 350.941 174.739 344.46 166.542 339.655ZM168.003 403.196C165.082 408.42 161.09 412.568 156.013 415.636C150.936 418.703 145.205 420.234 138.813 420.234C132.422 420.234 126.684 418.703 121.613 415.636C116.536 412.575 112.471 408.426 109.418 403.196C106.358 397.972 104.832 392.293 104.832 386.158C104.832 379.89 106.358 374.174 109.418 369.017C112.471 363.867 116.536 359.718 121.613 356.578C126.684 353.443 132.416 351.876 138.813 351.876C145.211 351.876 150.936 353.443 156.013 356.578C161.084 359.712 165.082 363.86 168.003 369.017C170.923 374.174 172.383 379.89 172.383 386.158C172.383 392.293 170.923 397.966 168.003 403.196Z" fill="white" />
      <path d="M279.435 339.552C271.856 334.814 262.441 332.445 251.184 332.445C240.2 332.445 230.961 334.814 223.455 339.552C215.949 344.29 210.248 350.668 206.358 358.679C202.469 366.697 200.518 375.717 200.518 385.745V437.375H221.159V387.835C221.159 381.427 222.268 375.571 224.497 370.275C226.721 364.984 230.053 360.659 234.506 357.313C238.952 353.966 244.514 352.295 251.184 352.295C258.133 352.295 263.865 353.966 268.384 357.313C272.898 360.659 276.308 365.015 278.599 370.378C280.895 375.741 282.04 381.633 282.04 388.041V437.369H302.681V385.533C302.681 375.638 300.7 366.691 296.737 358.673C292.775 350.661 287.008 344.296 279.435 339.552Z" fill="white" />
      <path d="M392.327 339.441C384.124 334.776 374.885 332.438 364.597 332.438C354.31 332.438 345.065 334.843 336.868 339.647C328.665 344.452 322.171 350.933 317.372 359.084C312.574 367.236 310.181 376.189 310.181 385.944C310.181 395.839 312.404 404.859 316.851 413.01C321.298 421.162 327.381 427.679 335.093 432.556C342.805 437.434 351.596 439.87 361.465 439.87C370.359 439.87 378.174 437.811 384.917 433.704C390.897 430.06 395.719 425.28 399.409 419.382V437.361H419.008V385.944C419.008 375.776 416.609 366.647 411.817 358.562C407.024 350.484 400.524 344.106 392.321 339.435L392.327 339.441ZM393.993 403.194C391.073 408.418 387.044 412.567 381.9 415.634C376.757 418.702 370.989 420.232 364.597 420.232C358.206 420.232 352.469 418.702 347.398 415.634C342.321 412.573 338.292 408.424 335.305 403.194C332.312 397.971 330.822 392.291 330.822 386.157C330.822 379.888 332.312 374.172 335.305 369.016C338.292 363.865 342.321 359.716 347.398 356.576C352.469 353.442 358.206 351.875 364.597 351.875C370.989 351.875 376.757 353.442 381.9 356.576C387.038 359.71 391.073 363.859 393.993 369.016C396.913 374.172 398.373 379.888 398.373 386.157C398.373 392.291 396.913 397.965 393.993 403.194Z" fill="white" />
      <path d="M461.126 308.402H440.485V334.946H420.262V352.919H440.485V400.576C440.485 408.382 441.975 415.245 444.968 421.168C447.955 427.09 452.371 431.724 458.206 435.065C464.046 438.412 471.134 440.082 479.471 440.082C483.221 440.082 486.977 439.523 490.727 438.412C494.477 437.3 497.676 435.903 500.318 434.233V415.209C497.815 417.025 494.859 418.483 491.454 419.6C488.049 420.718 484.681 421.271 481.343 421.271C475.224 421.271 470.329 419.388 466.645 415.628C462.962 411.868 461.12 406.638 461.12 399.951V352.919H500.318V334.946H461.12V308.402H461.126Z" fill="white" />
      <path d="M604.255 358.568C599.457 350.49 592.963 344.112 584.759 339.441C576.556 334.776 567.317 332.438 557.03 332.438C546.743 332.438 537.498 334.843 529.301 339.647C521.098 344.452 514.603 350.933 509.805 359.084C505.007 367.236 502.614 376.189 502.614 385.944C502.614 395.839 504.837 404.859 509.284 413.01C513.731 421.162 519.813 427.679 527.526 432.556C535.238 437.434 544.029 439.87 553.898 439.87C562.792 439.87 570.607 437.811 577.35 433.704C583.33 430.06 588.152 425.28 591.842 419.382V437.361H611.441V385.944C611.441 375.776 609.041 366.647 604.249 358.562L604.255 358.568ZM586.432 403.194C583.511 408.418 579.483 412.567 574.339 415.634C569.195 418.702 563.428 420.232 557.036 420.232C550.645 420.232 544.907 418.702 539.836 415.634C534.76 412.573 530.731 408.424 527.744 403.194C524.751 397.971 523.261 392.291 523.261 386.157C523.261 379.888 524.751 374.172 527.744 369.016C530.731 363.865 534.76 359.716 539.836 356.576C544.907 353.442 550.645 351.875 557.036 351.875C563.428 351.875 569.195 353.442 574.339 356.576C579.477 359.71 583.511 363.859 586.432 369.016C589.352 374.172 590.812 379.888 590.812 386.157C590.812 392.291 589.352 397.965 586.432 403.194Z" fill="white" />
      <path d="M417.556 166.593H359.296V115.985C366.019 119.464 373.637 121.407 381.704 121.407C408.906 121.407 431 99.1311 431 71.7037C431 44.2763 408.906 22 381.704 22C354.501 22 332.407 44.2763 332.407 71.7037V94.2963H287.593V35.5556C287.593 28.0548 281.587 22 274.148 22C266.709 22 260.704 28.0548 260.704 35.5556V94.2963H202.444C195.005 94.2963 189 100.351 189 107.852C189 115.353 195.005 121.407 202.444 121.407H260.704V172.015C253.981 168.536 246.363 166.593 238.296 166.593C211.094 166.593 189 188.869 189 216.296C189 243.724 211.094 266 238.296 266C265.499 266 287.593 243.724 287.593 216.296V193.704H332.407V252.444C332.407 259.945 338.413 266 345.852 266C353.291 266 359.296 259.945 359.296 252.444V193.704H417.556C424.995 193.704 431 187.649 431 180.148C431 172.647 424.995 166.593 417.556 166.593ZM381.704 49.1111C394.073 49.1111 404.111 59.2326 404.111 71.7037C404.111 84.1748 394.073 94.2963 381.704 94.2963C369.335 94.2963 359.296 84.1748 359.296 71.7037C359.296 59.2326 369.335 49.1111 381.704 49.1111ZM238.296 238.889C225.927 238.889 215.889 228.767 215.889 216.296C215.889 203.825 225.927 193.704 238.296 193.704C250.665 193.704 260.704 203.825 260.704 216.296C260.704 228.767 250.665 238.889 238.296 238.889ZM287.593 166.593V121.407H332.407V166.593H287.593Z" fill="#FD5201" />
    </g>
    <defs>
      <clipPath id="clip0_323_382">
        <rect width="614" height="468" fill="white" />
      </clipPath>
    </defs>
  </svg>

)
export default LogoLarge
