import CustomMessages from "../../components/customMessages/CustomMessage"
import data from "./data.json"
import { useWindowWidth } from "../../hooks/useWindowWidth"
import Envelop from "../../components/svg/Envelop"

const ConfirmEmail = () => {
  const screenWidth = useWindowWidth()

  return (
    <CustomMessages
      icon={
        screenWidth <= 767 ?
          <Envelop width={90} height={100} />
          :
          <Envelop width={185} height={150} />
      }
      data={data?.confirmEmail}
      isChillaxHeading={true}
    />
  )
}

export default ConfirmEmail