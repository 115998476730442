import WaitListModal from "../../components/modal/WaitListModal"
import sonataIconLarge from "../../assets/sonata-icon-large.png"
import { Grid, Heading, LinkTo, Para, AcronymBanner } from "./HomeComponents"
import data from "./data.json"
import note from "../../assets/note.png"
import comment from "../../assets/comment.png"
import reNote from "../../assets/renote.png"
import socialMediaHome from "../../assets/socialmediahome.png"
import basedIn from "../../assets/based-in.png"
import { useWindowWidth } from "../../hooks/useWindowWidth"
import logoHero from "../../assets/sonata-logo-hero-mbl.png"
import commentSmall from "../../assets/comment-small.png"
import renoteSmall from "../../assets/renote-small.png"
import socialMediaSmall from "../../assets/social-media-small.png"
import basedInSmall from "../../assets/iceland-small.png"
import DiscordSmall from "../../components/svg/DiscordSmall"
import DiscordLarge from "../../components/svg/DiscordLarge"
import commentXs from "../../assets/commnet-xs.png"
import commentXss from "../../assets/comment-xss.png"
import { SEO } from "../../components/meta/SEO"
import meta from "../../components/meta/meta.json"
import { AndroidIcon, AppleIcon, WebsiteIcon } from "../../components/svg/HeroIcons"

const discordLink = process.env.REACT_APP_DISCORD_LINK

function Home() {
  const screenWidth = useWindowWidth()

  return (
    <>
      <SEO
        title={meta?.home?.title}
        description={meta?.home?.description}
      />
      <div className="outer-container">

        <section className="hero-section">
          <div className="inner-container ">
            <Grid
              cssClass="hero-section__inner"
              gridLeftItem={
                <WaitListModal
                  show={true}
                  isHome={true}
                />
              }
              gridRightItem={
                <div className="hero-section__sonata-logo">
                  {screenWidth <= 1080 ?
                    <img src={logoHero} alt="sonata logo" />
                    :
                    <img src={sonataIconLarge} alt="sonata logo" />
                  }
                  <div className="hero-section__coming-soon">
                    <span className="hero-section__coming-soon__social">{data?.homePage?.hero?.social}</span>
                    <span className="hero-section__coming-soon__soon">{data?.homePage?.hero?.comingSoon}</span>
                  </div>
                  <div className="hero-section__icons">
                    <AndroidIcon
                      width={screenWidth <= 1080 ? 26 : 40}
                      height={screenWidth <= 1080 ? 26 : 40}
                    />
                    <AppleIcon
                      width={screenWidth <= 1080 ? 21 : 34}
                      height={screenWidth <= 1080 ? 26 : 40}
                    />
                    <WebsiteIcon
                      width={screenWidth <= 1080 ? 26 : 40}
                      height={screenWidth <= 1080 ? 26 : 40}
                    />
                  </div>
                </div>
              }
            />
          </div>
        </section>

        <section className="acronym-banner">
          <div className="inner-container">
            <AcronymBanner
              text={
                screenWidth <= 1080 ?
                  data?.homePage?.acronymBannerMbl
                  :
                  data?.homePage?.acronymBanner
              }
            />
          </div>
        </section>

        <section className="interactions">
          <div className="inner-container">
            <div className="interactions-wrapper">
              <Grid
                cssClass="interactions__inner"
                gridLeftItem={
                  <div>
                    <Heading
                      heading={data?.homePage?.interactions?.heading}
                    />
                    {data?.homePage?.interactions?.para.map((el, i) => {
                      return (
                        <Para
                          key={i}
                          para={el}
                        />
                      )
                    })}
                  </div>
                }
                gridRightItem={
                  <div className="inner-lower-wrapper">
                    <picture className="interactions__inner__img-note">
                      <img src={note} alt="sonata example note" />
                    </picture>
                  </div>
                }
              />
              <Grid
                cssClass="interactions__lower"
                gridLeftItem={
                  <div className="inner-lower-wrapper">
                    <picture className="interactions__lower__img-comment">
                      {screenWidth <= 1080 ?
                        screenWidth <= 425 ?
                          screenWidth <= 350 ?
                            <img src={commentXss} alt="sonata example comment" />
                            :
                            <img src={commentXs} alt="sonata example comment" />
                          :
                          <img src={commentSmall} alt="sonata example comment" />
                        :
                        <img src={comment} alt="sonata example comment" />
                      }
                    </picture>
                  </div>
                }
                gridRightItem={
                  <div className="inner-lower-wrapper renote-img">
                    <picture className="interactions__lower__img-re-note">
                      {screenWidth <= 1080 ?
                        <img src={renoteSmall} alt="sonata example re-note" />
                        :
                        <img src={reNote} alt="sonata example re-note" />
                      }
                    </picture>
                  </div>
                }
              />
            </div>
          </div>
        </section>

        <section className="freedom">
          <div className="inner-container">
            <Grid
              cssClass="freedom__inner"
              gridLeftItem={
                <div>
                  <Heading
                    heading={data?.homePage?.freedom?.heading}
                  />
                  {data?.homePage?.freedom?.para.map((el, i) => {
                    return (
                      <Para
                        key={i}
                        para={el}
                      />
                    )
                  })}
                  <LinkTo
                    href={data?.homePage?.freedom?.link?.href}
                    linkText={data?.homePage?.freedom?.link?.text}
                  />
                </div>
              }
            />
          </div>
          {screenWidth <= 1080 &&
            <div className="freedom__mbl" />
          }
        </section>

        <section className="social-media">
          <div className="inner-container">
            <div className="social-media-wrapper">
              <Heading
                heading={data?.homePage?.socialMedia?.heading}
              />
              <Grid
                cssClass="social-media__inner"
                gridLeftItem={
                  <div>
                    {data?.homePage?.socialMedia?.para.map((el, i) => {
                      return (
                        <Para
                          key={i}
                          para={el}
                        />
                      )
                    })}
                    <LinkTo
                      href={data?.homePage?.socialMedia?.link?.href}
                      linkText={data?.homePage?.socialMedia?.link?.text}
                    />
                  </div>
                }
                gridRightItem={
                  <div className="inner-lower-wrapper">
                    {screenWidth <= 1080 ?
                      <img className="social-media__inner__img" src={socialMediaSmall} alt="" />
                      :
                      <img className="social-media__inner__img" src={socialMediaHome} alt="" />
                    }
                  </div>
                }
              />
            </div>
          </div>
        </section>

        <section className="based-in">
          <div className="inner-container">
            <Grid
              cssClass="based-in__inner"
              gridLeftItem={
                <div>
                  <Heading
                    heading={data?.homePage?.basedIn?.heading}
                  />
                  <Para
                    para={data?.homePage?.basedIn?.para}
                  />
                </div>
              }
              gridRightItem={
                <div className="inner-lower-wrapper">
                  {screenWidth <= 1080 ?
                    <img className="based-in__inner__img" src={basedInSmall} alt="iceland flag" />
                    :
                    <img className="based-in__inner__img" src={basedIn} alt="iceland flag" />
                  }
                </div>
              }
            />
          </div>
        </section>

        <section className="no-investor">
          <div className="inner-container">
            <Grid
              cssClass="no-investor__inner"
              gridLeftItem={
                <div>
                  <Heading
                    heading={data?.homePage?.noInvestor?.heading}
                  />
                  <Para
                    para={data?.homePage?.noInvestor?.para}
                  />
                  <p className="home-page-para">
                    {data?.homePage?.noInvestor?.para2?.line1}{" "}
                    <LinkTo
                      href={data?.homePage?.noInvestor?.link?.href}
                      linkText={data?.homePage?.noInvestor?.link?.text}
                    />{" "}
                    {data?.homePage?.noInvestor?.para2?.line2}
                  </p>
                </div>
              }
            />
          </div>
          {screenWidth <= 1080 &&
            <div className="no-investor__mbl" />
          }
        </section>

        <section className="discord">
          <div className="discord__wrapper">
            <div className="inner-container">
              <div className="discord__join">
                <a
                  href={discordLink}
                  target="_blank"
                >
                  {data?.discord?.join}
                  <DiscordSmall />
                </a>
              </div>
            </div>
            {screenWidth >= 1080 &&
              <DiscordLarge color="#7285f4" />
            }
          </div>
        </section>
      </div>
    </>
  )
}

export default Home