const CodeExpiredIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width ? props?.width : 182}
    height={props?.height ? props?.width : 164}
    viewBox="0 0 182 164"
    fill="none"
    {...props}
  >
    <path fill="#FD5201" d="M81.909 45.636h18.182v54.546H81.909V45.636Z" />
    <path
      fill="#3C0061"
      fillRule="evenodd"
      d="M172.818 163.818H9.182v-9.091H.09v-36.363h9.09v-18.182h9.092V82h9.09V63.818h9.092V45.636h9.09V27.455h9.091V9.273h9.091V.182h54.546v9.09h9.091v18.183h9.09v18.181h9.091v18.182h9.091V82h9.091v18.182h9.091v18.182h9.091v36.363h-9.091v9.091Zm-100-127.273h-9.09v18.182h-9.092V72.91h-9.09v18.182h-9.091v18.182h-9.091v18.182h-9.091v18.181h145.454v-18.181h-9.091v-18.182h-9.091V91.091h-9.091V72.909h-9.09V54.727h-9.091V36.546h-9.091V18.363H72.818v18.181Z"
      clipRule="evenodd"
    />
    <path fill="#FD5201" d="M81.909 109.273h18.182v18.182H81.909v-18.182Z" />
  </svg>
)
export default CodeExpiredIcon
