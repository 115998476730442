const OppositionIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width ? props?.width : 40}
    height={props?.height ? props?.height : 40}
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      fill="#3C0061"
      d="M6.305 4.71 20 1.667 33.695 4.71A1.666 1.666 0 0 1 35 6.337v16.645a10 10 0 0 1-4.453 8.32L20 38.333 9.453 31.302A10 10 0 0 1 5 22.983V6.337A1.667 1.667 0 0 1 6.305 4.71Zm2.028 2.963v15.309a6.667 6.667 0 0 0 2.969 5.546l8.698 5.8 8.698-5.8a6.666 6.666 0 0 0 2.969-5.545V7.673L20 5.083 8.333 7.673ZM20 18.333A4.167 4.167 0 1 1 20 10a4.167 4.167 0 0 1 0 8.333Zm-7.455 8.334a7.5 7.5 0 0 1 14.908 0H12.545Z"
    />
  </svg>
);
export default OppositionIcon;
