import { createPortal } from "react-dom"
import WaitListIcon from "../svg/WaitListIcon"
import { useState } from "react"
import axios from "axios"
import { encode } from "base-64"
import data from "./data.json"
import { Formik, Field, Form, ErrorMessage } from "formik"
import DangerAlert from "../svg/DangerAlert"
import useWaitlistContext from "../../hooks/useWaitlistContext"
import Spinner from "../Spinner"
import SuccessModal from "./SuccessModal"
import { waitListApiSchema } from "../../validationSchema"

const waitListApi = process.env.REACT_APP_WAIT_LIST_API

const WaitListModal = ({
  show = "",
  close = "",
  isHome = false,
  disableClickOutside = true
}) => {
  const [successModal, setSuccessModal] = useState(() => false)
  const [hideForm, setHideForm] = useState(() => true)
  const [apiRes, setApiRes] = useState(() => "")
  const [formValuesRef, setFormValuesRef] = useState(() => { })
  const [submitting, setSubmitting] = useState(() => false)

  const { dispatch } = useWaitlistContext()

  const createWaitListContext = () => {
    localStorage.setItem("addedOnWaitList", true)
    dispatch({
      type: "WAITLIST_CONTEXT",
      payload: { waitlistState: true },
    })
  }

  const initialValues = {
    user_name: (formValuesRef && formValuesRef?.user_name) || "",
    user_email: (formValuesRef && formValuesRef?.user_email) || "",
    user_handle: (formValuesRef && formValuesRef?.user_handle) || ""
  }

  const FormComponent = ({ isFormInPortal }) => {

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={waitListApiSchema}
        onSubmit={(values, { resetForm }) => {
          setApiRes("")
          setFormValuesRef(values)
          setSubmitting(true)
          let encodedEmail = encode(values?.user_email)
          const headers = {
            "Content-Type": "text/plain",
          }
          axios
            .post(
              waitListApi,
              {
                request_type: "wait_list_user",
                ...values,
                user_email: encodedEmail,
              },
              { headers }
            )
            .then((res) => {
              isFormInPortal && setHideForm(false)
              setSuccessModal(true)
              setApiRes("")
              setFormValuesRef(null)
              setSubmitting(false)
              createWaitListContext()
              resetForm()
              setTimeout(() => {
                isFormInPortal && close()
                setSuccessModal(false)
              }, 2000)
            })
            .catch((err) => {
              setSubmitting(false)
              if (err?.response) {
                setApiRes(err?.response?.data?.msg)
              } else {
                setApiRes(err?.message)
              }
            })
        }}
      >
        {({ touched, errors }) => (
          <Form>
            <div>
              <label>
                <p>{data?.waitListModal?.label?.name}</p>
                <Field
                  name="user_name"
                  type="text"
                  disabled={submitting}
                  placeholder={data?.waitListModal?.namePlaceholder}
                  className={submitting ? "disabled-input" : null}
                />
              </label>
              <div className="form-field-error">
                <ErrorMessage
                  name="user_name"
                  component="small"
                />
              </div>
            </div>
            <div>
              <label>
                <p>{data?.waitListModal?.label?.email}</p>
                <Field
                  name="user_email"
                  type="email"
                  disabled={submitting}
                  placeholder={data?.waitListModal?.emailPlaceholder}
                  className={submitting ? "disabled-input" : null}
                />
              </label>
              <div className="form-field-error">
                <ErrorMessage
                  name="user_email"
                  component="small"
                />
              </div>
            </div>
            <div className="form-field">
              <label>
                <p className="wait-list-modal__content__handle">
                  <span>{data?.waitListModal?.label?.handle}</span>
                  <span>
                    {data?.waitListModal?.handleNote?.optional}
                  </span>
                </p>
                <Field
                  name="user_handle"
                  type="text"
                  disabled={submitting}
                  placeholder={data?.waitListModal?.handlePlaceholder}
                  className={submitting ? "disabled-input" : null}
                />
              </label>
              <p className="wait-list-modal__content__note">
                {data?.waitListModal?.handleNote?.note}
              </p>
              <div className="form-field-error">
                <ErrorMessage
                  name="user_handle"
                  component="small"
                />
              </div>
            </div>
            <div className="error-messages"
              data-is-error={apiRes ? true : false}
            >
              {
                // (
                //   touched?.user_email ||
                //   touched?.user_name ||
                //   touched?.user_handle
                // ) &&
                //   (
                //     errors?.user_name ||
                //     errors?.user_email ||
                //     errors?.user_handle
                //   ) ||
                apiRes ?
                  <small>
                    <>
                      <DangerAlert />
                      {
                        // (errors?.user_name && errors?.user_name) ||
                        // (errors?.user_email && errors?.user_email) ||
                        // (errors?.user_handle && errors?.user_handle) ||
                        (apiRes && apiRes)
                      }
                    </>
                  </small> : null
              }
            </div>
            <div className="submit-button-wrapper">
              {isFormInPortal &&
                <div>
                  <button
                    disabled={submitting}
                    className={
                      submitting
                        ? "wait-list-modal__content__cancel target-disabled"
                        : "wait-list-modal__content__cancel"
                    }
                    onClick={() => close()}
                  >
                    {data?.waitListModal?.submitText?.cancel}
                  </button>
                </div>
              }
              <button
                type="submit"
                disabled={submitting}
                className={
                  submitting
                    ? "wait-list-modal__content__submit target-disabled"
                    : "wait-list-modal__content__submit"
                }
              >
                {isFormInPortal ?
                  <>{data?.waitListModal?.submitText?.submit}</>
                  :
                  <>{data?.waitListModal?.submitText?.submitHome}</>
                }

                {submitting && <Spinner top={11} />}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    )
  }

  return isHome ?
    (
      <>
        <div className="wait-list-modal">
          <header className="wait-list-modal__header">
            <WaitListIcon />
            <h3 className="wait-list-modal__header__title">
              {data?.waitListModal?.heading}
            </h3>
          </header>
          <main className="wait-list-modal__content">
            <FormComponent isFormInPortal={false} />
          </main>
        </div>

        {successModal && (
          <SuccessModal
            para1={data?.waitListModal?.successText?.para1}
            para2={data?.waitListModal?.successText?.para2}
            styleBorder={true}
          />
        )}
      </>
    )
    :
    createPortal(
      <>
        {show && (
          <div
            className="wait-list-modal-container"
            onClick={() => disableClickOutside && close()}
          >
            {hideForm &&
              <div className="wait-list-modal" onClick={(e) => e.stopPropagation()}>
                <header className="wait-list-modal__header">
                  <WaitListIcon />
                  <h3 className="wait-list-modal__header__title">
                    {data?.waitListModal?.heading}
                  </h3>
                </header>
                <main className="wait-list-modal__content">
                  <FormComponent isFormInPortal={true} />
                </main>
              </div>
            }

            {successModal && (
              <SuccessModal
                para1={data?.waitListModal?.successText?.para1}
                para2={data?.waitListModal?.successText?.para2}
              />
            )}
          </div>
        )}
      </>,
      document.getElementById("wait-list-modal")
    )
}

export default WaitListModal
