import * as React from "react";
const FullScreenIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="#3C0061"
      d="M23.5 8.5v5.998h-1.502v-3.434L11.063 21.997h3.435V23.5H8.5v-5.998h1.502v3.434l10.934-10.934h-3.434V8.5H23.5Z"
    />
    <rect width={31} height={31} x={0.5} y={0.5} stroke="#3C0061" rx={5.5} />
  </svg>
);
export default FullScreenIcon;
