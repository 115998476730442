const CookiesIcon = (props) => (
  <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
  >
      <path
          d="M14 13.333c.555 0 1.027-.194 1.416-.584.39-.388.584-.86.584-1.416 0-.555-.195-1.027-.584-1.416A1.925 1.925 0 0 0 14 9.333c-.556 0-1.028.195-1.416.584-.39.389-.584.86-.584 1.416 0 .556.194 1.028.584 1.416.388.39.86.584 1.416.584ZM11.333 20c.556 0 1.028-.195 1.416-.584.39-.388.584-.86.584-1.416 0-.555-.194-1.027-.584-1.416A1.926 1.926 0 0 0 11.333 16c-.555 0-1.027.195-1.416.584-.39.389-.584.86-.584 1.416 0 .556.195 1.028.584 1.416.389.39.86.584 1.416.584ZM20 21.333c.378 0 .695-.128.95-.384a1.29 1.29 0 0 0 .383-.949c0-.378-.127-.695-.382-.95a1.294 1.294 0 0 0-.951-.383 1.29 1.29 0 0 0-.95.382 1.291 1.291 0 0 0-.384.951c0 .378.128.694.384.95.256.255.572.383.95.383Zm-4 8c-1.845 0-3.578-.35-5.2-1.05a13.455 13.455 0 0 1-4.234-2.85c-1.2-1.2-2.15-2.61-2.849-4.233-.7-1.622-1.05-3.355-1.05-5.2 0-1.867.4-3.689 1.2-5.467a13.642 13.642 0 0 1 3.35-4.6C8.65 4.644 10.35 3.7 12.317 3.1c1.966-.6 4.116-.656 6.45-.167-.2 1-.134 1.939.2 2.816a5.61 5.61 0 0 0 1.5 2.218A5.383 5.383 0 0 0 22.832 9.2c.911.222 1.867.167 2.867-.167-.69 1.534-.567 2.845.367 3.934.933 1.089 2 1.655 3.2 1.7.2 1.978-.012 3.855-.634 5.633s-1.538 3.333-2.75 4.667c-1.21 1.333-2.666 2.394-4.366 3.184-1.7.788-3.54 1.182-5.517 1.182Zm0-2.666c2.71 0 5.117-.934 7.217-2.8 2.1-1.867 3.25-4.245 3.45-7.134-1.112-.489-1.984-1.155-2.617-2a7.137 7.137 0 0 1-1.284-2.833 7.583 7.583 0 0 1-4.4-2.2A7.948 7.948 0 0 1 16.1 5.3c-1.778-.044-3.34.278-4.684.967-1.344.689-2.466 1.572-3.366 2.649a11.554 11.554 0 0 0-2.033 3.517C5.561 13.7 5.333 14.89 5.333 16c0 2.956 1.04 5.473 3.117 7.55 2.078 2.078 4.594 3.117 7.55 3.117Z"
          fill="#FC5201"
      />
  </svg>
)

export default CookiesIcon