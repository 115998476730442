import { useContext } from "react"
import { CookiesContext } from "../context/CookiesContext"

export const useCookiesContext = () => {
  const context = useContext(CookiesContext)

  if (!context) {
    throw Error("useCookiesContext must be used inside a CookiesContextProvider")
  }

  return context
};
