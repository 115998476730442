import { useState } from "react";
import {
  DownloadingLoader,
  PdfDownloading,
} from "../../components/PdfDownloading";
import mdFilePath from "../../content/sonata-company-policy.md";
import { useParseMarkdown } from "../../hooks/useParseMarkdown";
import Markdown from "markdown-to-jsx";
import { SEO } from "../../components/meta/SEO"
import meta from "../../components/meta/meta.json"

const SonataCompanyPolicy = ({ downloadBtn = true, isClone = false }) => {
  const { content, frontmatter } = useParseMarkdown(mdFilePath);
  const [downloading, setDownloading] = useState(() => false);

  return (
    <>
      <SEO
        title={meta?.companyPolicy?.title}
        description={meta?.companyPolicy?.description}
      />
      <DownloadingLoader downloading={downloading}
        component="sonata-company-policy"
      />
      <div
        className={isClone ? "sonata-company-policy clone-comp" : "sonata-company-policy"}
        id={isClone ? "sonata-company-policy" : null}
      >
        <h1 className="p-title">{frontmatter?.title}</h1>
        <div className="policy-subtitle download-pdf">
          <h3>{frontmatter?.motto}</h3>
          {downloadBtn &&
            <PdfDownloading
              setDownloading={setDownloading}
              policy="sonata-company-policy"
            />
          }
        </div>
        <div className="sonata-company-policy__content">
          <Markdown>{content}</Markdown>
        </div>
      </div>
    </>
  );
};

export default SonataCompanyPolicy;
