const VerifyEmailIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width ? props?.width : 184}
    height={props?.height ? props?.height : 159}
    viewBox="0 0 184 159"
    fill="none"
    {...props}
  >
    <path
      stroke="#3C0061"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={16.667}
      d="M175.334 83.333V25a16.669 16.669 0 0 0-16.667-16.667H25.334A16.667 16.667 0 0 0 8.667 25v100c0 9.167 7.5 16.667 16.667 16.667h66.667"
    />
    <path
      stroke="#3C0061"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={16.667}
      d="m175.334 33.333-74.75 47.5a16.166 16.166 0 0 1-17.166 0l-74.75-47.5"
    />
    <path
      stroke="#FD5201"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={16.667}
      d="M125.333 133.333 142 150l33.334-33.333"
    />
  </svg>
)
export default VerifyEmailIcon
