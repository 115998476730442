import * as Yup from "yup";

export const contactUsSchema = Yup.object({
  name: Yup.string().min(3, "Too short").matches(/^[a-z ,.'-]+$/i, "Only alphabets are allowed").required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  subject: Yup.string()
    .min(3),
  // .required("Subject is required"),
  message: Yup.string().min(10).required("Message is required"),
})

export const reportAbuseSchema = Yup.object({
  type: Yup.string().required("Report type is required"),
  url: Yup.string().url("Invalid reported url").required("Reported URL is required"),
  message: Yup.string().min(10).required("Message is required"),
})

export const dmcaPage = Yup.object({
  owner_name: Yup.string().min(3, "Owner name must contain al least 3 chracters").matches(/^[a-z ,.'-]+$/i, "Only alphabets are allowed").required("Owner's name is required"),
  your_name: Yup.string().min(3, "Your name must contain at least 3 chracters").matches(/^[a-z ,.'-]+$/i, "Only alphabets are allowed").required("Your name is required"),
  company: Yup.string().required("Company name is required"),
  job_title: Yup.string().required("Job title is required"),
  your_email: Yup.string().email("Invalid email").required("Your email is required"),
  address: Yup.string().required("Street address is required"),
  city: Yup.string().matches(/^[a-z ,.'-]+$/i, "Only alphabets are allowed").required("City is required"),
  state: Yup.string().matches(/^[a-z ,.'-]+$/i, "Only alphabets are allowed").required("State or Province is required"),
  postal_code: Yup.number().required("Postal code is required"),
  country: Yup.string().matches(/^[a-z ,.'-]+$/i, "Only alphabets are allowed").required("Country is required"),
  phone_number: Yup.number().required("Phone Number is required"),

})

export const dmcaPage2 = Yup.object({
  work_description: Yup.string().required("Description is required"),
  work_link: Yup.string().url("Invalid origional url").required("Origional URL is required"),
  reported_url: Yup.string().url("Invalid reported url").required("Reported URL is required"),
  infringment_description: Yup.string().required("Infringment description is required"),
})

export const dmcaPage3 = Yup.object({
  acknowledgement: Yup.bool().oneOf([true], "Please accept acknowledgement"),
  faith: Yup.bool().oneOf([true], "Please accept good faith beleif"),
  authority: Yup.bool().oneOf([true], "Please accept authority to act"),
  sign: Yup.string().min(3, "Your signature must contain at least 3 chracters").matches(/^[a-z ,.'-]+$/i, "Only alphabets are allowed").required("Your signature is required")
})

export const waitListApiSchema = Yup.object({
  user_name: Yup.string()
    .required("Name is required")
    .max(20, "Name must be 20 characters or less")
    .min(4, "Name must be at least 4 characters")
    .matches(/^[a-zA-Z0-9\s]+$/, "Name can only contain alphabets and numbers")
    .test("has-letter", "Name must contain at least one letter", (value) =>
      /[a-zA-Z]/.test(value)
    ),
  user_email: Yup.string()
    .required("Email is required")
    .email("Invalid email address"),
  user_handle: Yup.string()
    .min(4, "Handle must be at least 4 characters")
    // .max(20, "Handle must be 20 characters or less")
    .matches(/^((?!\s).)*$/, "Handle must not contain any spaces")
    .matches(
      /^[a-z0-9_-]+$/,
      "Handle must consist of only small letters, underscores, hyphens and numbers"
    )
    .test("has-letter", "Handle must contain at least one letter", (value) =>
      /[a-zA-Z]/.test(value)
    )
    .nullable(),
})