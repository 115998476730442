const CustomMessages = ({
  icon,
  data,
  isPageNotFound = false,
  isChillaxHeading = false,
  isEmailVerified = false
}) => {
  return (
    <section
      className="custom-messages"
      data-is-page-not-found={isPageNotFound ? true : false}
    >
      <div className="custom-message">
        {icon}
        <p className="custom-message__para1" 
        data-is-chillax-font={isChillaxHeading ? true : false}
        data-is-email-verified-res={isEmailVerified ? true : false}
        >
          {data?.para1}
        </p>
        <p className="custom-message__para2">
          {data?.para2}
        </p>
      </div>
    </section>
  )
}

export default CustomMessages