import React from "react"
import { NavLink } from "react-router-dom"

export default function Dropdown({ items, onClick = "", activeClass }) {
  return (
    <div className="dropdown-menu">
      <div>
        {items?.map((el, i) => {
          return (
            <NavLink
              className={({ isActive }) =>
                isActive ? `${activeClass}` : null
              }
              to={el?.slug}
              key={i}
              onClick={onClick}
            >
              {el?.linkText}
            </NavLink>
          )
        })}
      </div>
    </div>
  );
}
