const ResponsibilityIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width ? props?.width : 38}
    height={props?.height ? props?.height : 38}
    viewBox="0 0 38 38"
    fill="none"
    {...props}
  >
    <path
      stroke="#FD5201"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.333}
      d="M10.665 27.333a4.167 4.167 0 1 0 0-8.333 4.167 4.167 0 0 0 0 8.333ZM27.333 27.333a4.167 4.167 0 1 0 0-8.333 4.167 4.167 0 0 0 0 8.333ZM18.999 10.666a4.167 4.167 0 1 0 0-8.333 4.167 4.167 0 0 0 0 8.333Z"
    />
    <path
      stroke="#FD5201"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.333}
      d="M2.332 35.666a8.333 8.333 0 1 1 16.667 0 8.333 8.333 0 1 1 16.667 0M27.331 19a8.333 8.333 0 1 0-16.667 0"
    />
  </svg>
);
export default ResponsibilityIcon;
