import frame1 from "../../assets/pressAssets/frame-2359.png"
import frame2 from "../../assets/pressAssets/frame-2363.png"
import logoPack from "../../assets/pressAssets/Sonata_Logo_Pack.zip"
import platformImages from "../../assets/pressAssets/Sonata_Platform_Images.zip"

export const bottomContent = {
  "pressReleaseFirstLook": {
    "img1": frame1,
    "img2": frame2,
    "asset1": logoPack,
    "asset2": platformImages
  }
}