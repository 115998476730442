import { useContext } from "react";
import { WaitListContext } from "../context/WaitListContext";

const useWaitlistContext = () => {
  const context = useContext(WaitListContext);

  if (!context) {
    throw Error("WaitlstContext must be used inside a waitlistContextProvider");
  }

  return context;
};

export default useWaitlistContext;
