import { useWindowWidth } from "../../hooks/useWindowWidth"
import generationDiscovery from "../../assets/generation-discovery.png"
import generationAttention from "../../assets/generation-attention.png"
import generationOpposition from "../../assets/generation-opposition.png"
import SearchIcon from "../../components/svg/SearchIcon"
import AttentionIcon from "../../components/svg/AttentionIcon"
import OppositionIcon from "../../components/svg/OppositionIcon"
import ResponsibilityIcon from "../../components/svg/ResponsibilityIcon"

export const GenerationDiscovery = () => {
  const screenWidth = useWindowWidth()

  return (
    <>
      {screenWidth <= 767 ?
        <img
          src={generationDiscovery}
          alt=""
          width={155}
          height={172}
        />
        :
        <img
          src={generationDiscovery}
          alt=""
        />
      }
    </>
  )
}

export const GenerationAttention = () => {
  const screenWidth = useWindowWidth()

  return (
    <>
      {screenWidth <= 767 ?
        <img
          src={generationAttention}
          alt=""
          width={169}
          height={224}
        />
        :
        <img
          src={generationAttention}
          alt=""
        />
      }
    </>
  )
}

export const GenerationOpposition = () => {
  const screenWidth = useWindowWidth()

  return (
    <>
      {screenWidth <= 767 ?
        <img
          src={generationOpposition}
          alt=""
          width={194}
          height={180}
        />
        :
        <img
          src={generationOpposition}
          alt=""
        />
      }
    </>
  )
}

export const GenSearchIcon = () => {
  const screenWidth = useWindowWidth()

  return (
    <>
      {screenWidth <= 767 ?
        <SearchIcon width={32} height={32} />
        :
        <SearchIcon />
      }
    </>
  )
}

export const GenAttentionIcon = () => {
  const screenWidth = useWindowWidth()

  return (
    <>
      {screenWidth <= 767 ?
        <AttentionIcon width={32} height={32} />
        :
        <AttentionIcon />
      }
    </>
  )
}

export const GenOppositionIcon = () => {
  const screenWidth = useWindowWidth()

  return (
    <>
      {screenWidth <= 767 ?
        <OppositionIcon width={32} height={32} />
        :
        <OppositionIcon />
      }
    </>
  )
}

export const GenResponsibilityIcon = () => {
  const screenWidth = useWindowWidth()

  return (
    <>
      {screenWidth <= 767 ?
        <ResponsibilityIcon width={26} height={26} />
        :
        <ResponsibilityIcon />
      }
    </>
  )
}