const Hamburger = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      fill="#444"
      d="M8.46 7 14 12.54V14h-1.46L7 8.46 1.46 14H0v-1.46L5.54 7 0 1.46V0h1.46L7 5.54 12.54 0H14v1.46L8.46 7Z"
    />
  </svg>
)
export default Hamburger
