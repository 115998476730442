import * as React from "react";
const LaunchedSvgIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M19.5 19.5h-14v-11h14m0-5h-1v-2h-2v2h-8v-2h-2v2h-1a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-14a2 2 0 0 0-2-2Zm-2.47 8.06-1.06-1.06-4.88 4.88-2.12-2.12-1.06 1.06 3.18 3.18 5.94-5.94Z"
    />
  </svg>
);
export default LaunchedSvgIcon;
