import { Routes, Route } from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy";
import CookiesPolicy from "./CookiesPolicy";
import AcceptableUsePolicy from "./AcceptableUsePolicy";
import ProhibitedContentPolicy from "./ProhibitedContentPolicy";
import SonataCompanyPolicy from "./SonataCompanyPolicies";
import TermsOfService from "./TermsOfService";
import SonataAmplificationPolicy from "./SonataAmplificationPolicy";
import HandlePolicy from "./HandlePolicy";
import PrivacyInfoBreakdown from "./PrivacyInfoBreakdown";

const PoliciesRouter = () => {
  return (
    <Routes>
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/cookie-policy" element={<CookiesPolicy />} />
      <Route
        path="/sonata-company-policies"
        element={<SonataCompanyPolicy />}
      />
      <Route path="/acceptable-use-policy" element={<AcceptableUsePolicy />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route
        path="/prohibited-content-policy"
        element={<ProhibitedContentPolicy />}
      />
      <Route
        path="/amplification-policy"
        element={<SonataAmplificationPolicy />}
      />
      <Route
        path="/handle-policy"
        element={<HandlePolicy />}
      />
      <Route
        path="/privacy-info-breakdown"
        element={<PrivacyInfoBreakdown />}
      />
    </Routes>
  );
};

export default PoliciesRouter;
