const Headphones = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={28}
    fill="none"
    {...props}
  >
    <path
      stroke="#FD5201"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M25.667 11.667a10.667 10.667 0 1 0-21.334 0"
    />
    <path
      stroke="#FD5201"
      strokeWidth={2}
      d="M25.667 21v.666A2.667 2.667 0 0 1 23 24.333h-4.667m-16.666-6.75v-2.5a2.667 2.667 0 0 1 2.02-2.587l2.32-.582A.802.802 0 0 1 7 12.69v7.284a.8.8 0 0 1-.995.778l-2.32-.58a2.667 2.667 0 0 1-2.018-2.587v-.001Zm26.666 0v-2.5a2.667 2.667 0 0 0-2.02-2.587l-2.32-.582a.802.802 0 0 0-.993.776v7.284a.8.8 0 0 0 .993.778l2.32-.58a2.667 2.667 0 0 0 2.02-2.587v-.001Z"
    />
    <path
      stroke="#FD5201"
      strokeWidth={2}
      d="M17 26.332h-4a2 2 0 0 1 0-4h4a2 2 0 0 1 0 4Z"
    />
  </svg>
)
export default Headphones
