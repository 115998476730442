const DropDownArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={8}
    fill="none"
    {...props}
  >
    <path
      fill={props?.color ? props?.color : "#fff"}
      d="M11.29 1.289a.996.996 0 0 0-1.41 0L6 5.169l-3.88-3.88a.997.997 0 0 0-1.41 1.41l4.59 4.59a.996.996 0 0 0 1.41 0l4.59-4.59c.38-.38.38-1.02-.01-1.41Z"
    />
  </svg>
)
export default DropDownArrow
