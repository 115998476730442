import { Link } from "react-router-dom"

export const Grid = ({
  cssClass,
  gridRightItem = "",
  gridLeftItem
}) => {
  return (
    <div
      className={cssClass ? cssClass : null}
    >
      {gridLeftItem}
      {gridRightItem}
    </div>
  )
}

export const Heading = ({ heading }) => {
  
  return (
    <h2 className="home-page-heading"
      dangerouslySetInnerHTML={{
        __html: heading
      }}
    />
  )
}

export const Para = ({ para, link }) => {

  return (
    <p
      className="home-page-para"
      dangerouslySetInnerHTML={{
        __html: para
      }}
    />
  )
}

export const LinkTo = ({ href, linkText }) => {

  return (
    <Link className="home-page-links" to={href}>{linkText}</Link>
  )
}

export const AcronymBanner = ({ text }) => {

  return (
    <p
      dangerouslySetInnerHTML={{ __html: text }}
    />
  )
}