const DiscordSmall = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={43}
    height={33}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M35.54 2.66A33.966 33.966 0 0 0 27 0a.18.18 0 0 0-.14.06c-.36.66-.78 1.52-1.06 2.18a32.18 32.18 0 0 0-9.6 0c-.28-.68-.7-1.52-1.08-2.18-.02-.04-.08-.06-.14-.06-3 .52-5.86 1.42-8.54 2.66-.02 0-.04.02-.06.04C.94 10.84-.56 18.76.18 26.6c0 .04.02.08.06.1 3.6 2.64 7.06 4.24 10.48 5.3.06.02.12 0 .14-.04.8-1.1 1.52-2.26 2.14-3.48.04-.08 0-.16-.08-.18a24.62 24.62 0 0 1-3.28-1.56c-.08-.04-.08-.16-.02-.22.22-.16.44-.34.66-.5.04-.04.1-.04.14-.02 6.88 3.14 14.3 3.14 21.1 0 .04-.02.1-.02.14.02.22.18.44.34.66.52.08.06.08.18-.02.22-1.04.62-2.14 1.12-3.28 1.56-.08.02-.1.12-.08.18.64 1.22 1.36 2.38 2.14 3.48.06.02.12.04.18.02 3.44-1.06 6.9-2.66 10.5-5.3a.11.11 0 0 0 .06-.1c.88-9.06-1.46-16.92-6.2-23.9-.02-.02-.04-.04-.08-.04Zm-21.5 19.16c-2.06 0-3.78-1.9-3.78-4.24 0-2.34 1.68-4.24 3.78-4.24 2.12 0 3.8 1.92 3.78 4.24 0 2.34-1.68 4.24-3.78 4.24Zm13.94 0c-2.06 0-3.78-1.9-3.78-4.24 0-2.34 1.68-4.24 3.78-4.24 2.12 0 3.8 1.92 3.78 4.24 0 2.34-1.66 4.24-3.78 4.24Z"
    />
  </svg>
)
export default DiscordSmall
