import { useState } from "react";
import {
  DownloadingLoader,
  PdfDownloading,
} from "../../components/PdfDownloading";
import mdFilePath from "../../content/privacy-policy.md";
import { useParseMarkdown } from "../../hooks/useParseMarkdown";
import Markdown from "markdown-to-jsx";
import { SEO } from "../../components/meta/SEO"
import meta from "../../components/meta/meta.json"

const PrivacyPolicy = ({ downloadBtn = true, isClone = false }) => {
  const { content, frontmatter } = useParseMarkdown(mdFilePath);
  const [downloading, setDownloading] = useState(() => false)

  return (
    <>
      <SEO
        title={meta?.privacyPolicy?.title}
        description={meta?.privacyPolicy?.description}
      />
      <DownloadingLoader downloading={downloading} component="privacy-policy" />
      <div
        className={isClone ? "privacy-policy clone-comp" : "privacy-policy"}
        id={isClone ? "privacy-policy" : null}
      >
        <h1 className="p-title">{frontmatter?.title}</h1>
        <div className="effective-date download-pdf">
          <time>{frontmatter?.date}</time>
          {downloadBtn &&
            <PdfDownloading
              setDownloading={setDownloading}
              policy="privacy-policy"
            />
          }
        </div>
        <div className="privacy-policy__content">
          <Markdown>{content}</Markdown>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
