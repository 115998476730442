import mdFilePath from "../../content/press-releases/press-release-titles.md"
import { useParseMarkdown } from "../../hooks/useParseMarkdown"
import TocArrow from "../../components/svg/TocArrow"
import Markdown from "markdown-to-jsx"
import { useLocation } from "react-router-dom"
import { useEffect, useState } from "react"
import PressReleases from "./PressReleases"
import meta from "../../components/meta/meta.json"
import { SEO } from "../../components/meta/SEO"

const PressRelease = () => {
  const { content, frontmatter } = useParseMarkdown(mdFilePath)
  const [isPressRelease, setIsPressRelease] = useState(() => false)
  const [pressFile, setPressFile] = useState(() => "")
  const location = useLocation()

  // get press release name from location
  useEffect(() => {
    setIsPressRelease(false)
    const pressReleaseHash = location?.hash
    const pressRelease = pressReleaseHash.replace("#", "")
    setPressFile(pressRelease)
  }, [location?.hash])

  return (
    <>
      <SEO
        title={meta?.pressRelease?.title}
        description={meta?.pressRelease?.description}
      />
      <div
        className="press-releases"
      >
        {!isPressRelease &&
          <>
            <h1 className="p-title">
              {frontmatter?.title}
            </h1>
            <div className="press-releases__titles">
              <Markdown
                options={{
                  overrides: {
                    TocArrow: {
                      component: TocArrow
                    }
                  }
                }}
              >
                {content}
              </Markdown>
            </div>
          </>
        }
        <PressReleases
          setIsPressRelease={setIsPressRelease}
          pressFile={pressFile} // press release markdown file name
          isPressRelease={isPressRelease}
        />
      </div>
    </>
  )
}

export default PressRelease