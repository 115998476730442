import mdFilePath from "../../content/privacy-info-breakdown.md"
import { useParseMarkdown } from "../../hooks/useParseMarkdown"
import Markdown from "markdown-to-jsx"
import { DownloadingLoader, PdfDownloading } from "../../components/PdfDownloading"
import { useState } from "react"
import { SEO } from "../../components/meta/SEO"
import meta from "../../components/meta/meta.json"

const PrivacyInfoBreakdown = ({ downloadBtn = true, isClone = false }) => {
  const { content, frontmatter } = useParseMarkdown(mdFilePath)
  const [downloading, setDownloading] = useState(() => false)

  return (
    <>
      <SEO
        title={meta?.privacyInfoBreakdown?.title}
        description={meta?.privacyInfoBreakdown?.description}
      />
      <DownloadingLoader downloading={downloading} component="privacy-info-breakdown" />
      <div
        className={isClone ? "privacy-info-breakdown clone-comp" : "privacy-info-breakdown"}
        id={isClone ? "privacy-info-breakdown" : null}
      >
        <h1 className="p-title">{frontmatter?.title}</h1>
        <div className="effective-date download-pdf">
          <time>{frontmatter?.date}</time>
          {downloadBtn &&
            <PdfDownloading
              setDownloading={setDownloading}
              policy="privacy-info-breakdown"
            />
          }
        </div>
        <div className="privacy-info-breakdown__content">
          <Markdown>{content}</Markdown>
        </div>
      </div>
    </>
  )
}

export default PrivacyInfoBreakdown