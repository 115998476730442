const WaitListSuccess = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={121}
    height={120}
    fill="none"
    {...props}
  >
    <path
      fill="#3C0061"
      stroke="#3C0061"
      strokeWidth={0.5}
      d="M62.938 44.25v.25H84.23v-5.75H62.938v5.5ZM84.23 60v-.25H62.938v5.75H84.23V60Zm-10.524 42.25h.599l-.422-.426-5.198-5.25-.073-.074H37.448v-73h51.48V76.481l.427-.432 5.198-5.25.073-.073V17.75H31.75v84.5h41.956Zm-23.69-56.758.177.18.178-.18 9.624-9.72.174-.176-.174-.176-3.655-3.692-.178-.179-.177.18-5.792 5.85L47 34.352l-.178-.179-.177.18-3.655 3.69-.174.177.174.176 7.025 7.095Zm0 21 .177.18.178-.18 9.624-9.72.174-.176-.174-.176-3.655-3.692-.178-.179-.177.18-5.792 5.85L47 55.352l-.178-.179-.177.18-3.655 3.69-.174.177.174.176 7.025 7.095Zm6.324 7.236-.178-.179-.177.18-5.792 5.85L47 76.352l-.178-.179-.177.18-3.655 3.69-.174.177.174.176 7.025 7.095.178.18.178-.18 9.624-9.72.174-.176-.174-.176-3.655-3.692Z"
    />
    <path
      fill="#FD5201"
      stroke="#FD5201"
      strokeWidth={0.5}
      d="m104.178 77.771.174-.175-.174-.176-3.655-3.692-.177-.179-.178.18-18.787 18.934-7.092-7.123-.178-.178-.177.179-3.655 3.691-.173.176.173.176 10.924 11.074.178.18.178-.18 22.619-22.887Z"
    />
  </svg>
)
export default WaitListSuccess
