import { useState } from "react"
import { DownloadingLoader, PdfDownloading } from "../../components/PdfDownloading"
import mdFilePath from "../../content/acceptable-use-policy.md"
import { useParseMarkdown } from "../../hooks/useParseMarkdown"
import Markdown from "markdown-to-jsx"
import meta from "../../components/meta/meta.json"
import { SEO } from "../../components/meta/SEO"

const AcceptableUsePolicy = ({ downloadBtn = true, isClone = false }) => {
  const { content, frontmatter } = useParseMarkdown(mdFilePath)
  const [downloading, setDownloading] = useState(() => false)

  return (
    <>
      <SEO
        title={meta?.acceptableUsePolicy?.title}
        description={meta?.acceptableUsePolicy?.description}
      />
      <DownloadingLoader downloading={downloading} component="acceptable-use-policy" />
      <div
        className={isClone ? "acceptable-use-policy clone-comp" : "acceptable-use-policy"}
        id={isClone ? "acceptable-use-policy" : null}
      >
        <h1 className="p-title">
          {frontmatter?.title}
        </h1>
        <div className="effective-date download-pdf">
          <time>
            {frontmatter?.date}
          </time>
          {downloadBtn &&
            <PdfDownloading
              setDownloading={setDownloading}
              policy="acceptable-use-policy"
            />
          }
        </div>
        <div className="acceptable-use-policy__content">
          <Markdown>
            {content}
          </Markdown>
          <div className="effective-date">
            <time>
              {frontmatter?.date}
            </time>
          </div>
        </div>
      </div>
    </>
  )
}

export default AcceptableUsePolicy