import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Dropdown from "../Dropdown";
import { useRef } from "react";
import data from "./data.json"
import OutsideClickHandler from "react-outside-click-handler";
import WaitListModal from "../modal/WaitListModal";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import DropDownArrow from "../svg/DropDownArrow";
import useWaitlistContext from "../../hooks/useWaitlistContext";
import Hamburger from "../svg/Hamburger";
import SonataLogo from "../svg/SonataLogo";
import JoinWaitlistAlert from "./JoinWaitlistAlert";
import ToggleMenu from "../svg/ToggleMenu";

function Header({
  isHomePage,
  isPolicyPages,
  isArticles
}) {
  const [showDropdown, setShowDropdown] = useState(() => false);
  const [showContact, setShowContact] = useState(() => false);
  const [showArticle, setShowArticle] = useState(() => false);
  const [showToggle, setShowToggle] = useState(() => false);
  const sidebarLinks = useRef();
  const [waitListModal, setWaitListModal] = useState(() => false);
  const screenWidth = useWindowWidth();
  const { waitlistState } = useWaitlistContext();
  const [isPolicyDrop, setIsPolicyDrop] = useState(() => false);
  const [isContactDrop, setIsContactDrop] = useState(() => false);
  const [isArticleDrop, setIsArticleDrop] = useState(() => false);
  const [clickCounter, setClickCounter] = useState(1);

  const handleWaitListModal = () => {
    setWaitListModal((prevState) => !prevState);
  };

  const closeSidebar = (e) => {
    if (sidebarLinks.current && !sidebarLinks.current.contains(e.target)) {
      setShowToggle(false);
    }
  };

  return (
    <>
      <div className="header-container">
        <div className="header-container__inner">
          {screenWidth <= 1050 ? (
            <>
              <div className="logo">
                <Link to="/">
                  <SonataLogo />
                </Link>
              </div>

              <div
                className="toggle-sidebar"
                onClick={() => setShowToggle(true)}
              >
                <ToggleMenu />
              </div>

              {showToggle && (
                <div onClick={closeSidebar} className="sidebar-container">
                  <div className="mobile-links" ref={sidebarLinks}>
                    <div
                      className="toggle-sidebar"
                      onClick={() => setShowToggle(false)}
                      data-is-menu-open={true}
                    >
                      <Hamburger />
                    </div>
                    <NavLink
                      to={data?.links?.home?.slug}
                      onClick={() => setShowToggle(false)}
                      className={({ isActive }) =>
                        isActive ? "active-mbl-link" : null
                      }
                    >
                      {data?.links?.home?.linkText}
                    </NavLink>
                    <NavLink
                      to={data?.links?.about?.slug}
                      onClick={() => setShowToggle(false)}
                      className={({ isActive }) =>
                        isActive ? "active-mbl-link" : null
                      }
                    >
                      {data?.links?.about?.linkText}
                    </NavLink>
                    <span
                      onClick={() => {
                        setShowDropdown((prevState) => !prevState);
                        setShowContact(false);
                        setShowArticle(false);
                      }}
                      className={showDropdown ? "active-li" : null}
                    >
                      <div className="mbl-li">
                        {data?.links?.policies?.linkText}
                        <DropDownArrow color="#3c0061" />
                      </div>
                      {showDropdown && (
                        <Dropdown
                          items={data?.policiesDropdown}
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowToggle(false);
                          }}
                          activeClass="active-mbl-link"
                        />
                      )}
                    </span>
                    <NavLink
                      to={data?.links?.contact?.slug}
                      onClick={() => setShowToggle(false)}
                      className={({ isActive }) =>
                        isActive ? "active-mbl-link" : null
                      }
                    >
                      {data?.links?.contact?.linkText}
                    </NavLink>
                    <span
                      onClick={() => {
                        setShowArticle((prevState) => !prevState);
                        setShowDropdown(false);
                        setShowContact(false);
                      }}
                      className={showArticle ? "active-li" : null}
                    >
                      <div className="mbl-li">
                        {data?.links?.articles?.linkText}
                        <DropDownArrow color="#3c0061" />
                      </div>
                      {showArticle && (
                        <Dropdown
                          items={data?.articleDropdown}
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowToggle(false);
                          }}
                          activeClass="active-mbl-link"
                        />
                      )}
                    </span>
                    <NavLink
                      to={data?.links?.roadmap?.slug}
                      onClick={() => setShowToggle(false)}
                      className={({ isActive }) =>
                        isActive ? "active-mbl-link" : null
                      }
                    >
                      {data?.links?.roadmap?.linkText}
                    </NavLink>
                    <NavLink
                      to={data?.links?.whitepaper?.slug}
                      className={({ isActive }) =>
                        isActive
                          ? "whitepaper-mbl active-mbl-link"
                          : "whitepaper-mbl"
                      }
                      onClick={() => setShowToggle(false)}
                    >
                      {data?.links?.whitepaper?.linkText}
                    </NavLink>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="logo">
                <Link to="/">
                  <SonataLogo />
                </Link>
              </div>

              <div
                className="desktop-links"
                onContextMenu={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
                onCut={(e) => e.preventDefault()}
              >
                <div>
                  <NavLink
                    to={data?.links?.about?.slug}
                    className={({ isActive }) =>
                      isActive ? "active-background" : null
                    }
                  >
                    {data?.links?.about?.linkText}
                  </NavLink>
                </div>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setShowDropdown(false);
                    setIsPolicyDrop(false);
                  }}
                >
                  <span
                    data-is-active-dropdown-link={isPolicyPages ? true : false}
                    onClick={() => {
                      setIsPolicyDrop(true);
                      setShowDropdown(true);
                      if (clickCounter % 2 === 0) {
                        setShowDropdown(false);
                        setIsPolicyDrop(false);
                      }
                      setClickCounter(clickCounter + 1);
                    }}
                    className={showDropdown ? "active-li" : null}
                    onMouseEnter={() => {
                      setShowDropdown(true);
                    }}
                    onMouseLeave={() => {
                      if (!isPolicyDrop) {
                        setShowDropdown(false);
                      }
                    }}
                  >
                    {data?.links?.policies?.linkText} <DropDownArrow />
                    {showDropdown && (
                      <Dropdown
                        items={data?.policiesDropdown}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowDropdown(false);
                          setIsPolicyDrop(false);
                        }}
                        activeClass="active-dropdown"
                      />
                    )}
                  </span>
                </OutsideClickHandler>
                {/* <OutsideClickHandler
                  onOutsideClick={() => {
                    setShowContact(false)
                    setIsContactDrop(false)
                  }}
                >
                  <span
                    onClick={() => {
                      setShowContact(true)
                      setIsContactDrop(true)
                      if (clickCounter % 2 === 0) {
                        setShowContact(false)
                        setIsContactDrop(false)
                      }
                      setClickCounter(clickCounter + 1);
                    }}
                    className={showContact ? "active-li" : null}
                    onMouseEnter={() => {
                      setShowContact(true)
                    }}
                    onMouseLeave={() => {
                      if (!isContactDrop) {
                        setShowContact(false)
                      }
                    }}
                  >
                    {data?.links?.contact?.linkText} <DropDownArrow />
                    {showContact && (
                      <Dropdown
                        items={data?.contactDropdown}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowContact(false)
                          setIsContactDrop(false)
                        }}
                        activeClass="active-dropdown"
                      />
                    )}
                  </span>
                </OutsideClickHandler> */}
                <div>
                  <NavLink
                    to={data?.links?.contact?.slug}
                    className={({ isActive }) =>
                      isActive ? "active-background" : null
                    }
                  >
                    {data?.links?.contact?.linkText}
                  </NavLink>
                </div>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setShowArticle(false);
                    setIsArticleDrop(false);
                  }}
                >
                  <span
                    data-is-active-dropdown-link={isArticles ? true : false}
                    onClick={() => {
                      setShowArticle(true);
                      setIsArticleDrop(true);
                      if (clickCounter % 2 === 0) {
                        setShowArticle(false);
                        setIsArticleDrop(false);
                      }
                      setClickCounter(clickCounter + 1);
                    }}
                    className={showArticle ? "active-li" : null}
                    onMouseEnter={() => setShowArticle(true)}
                    onMouseLeave={() => {
                      if (!isArticleDrop) {
                        setShowArticle(false);
                      }
                    }}
                  >
                    {data?.links?.articles?.linkText}
                    <DropDownArrow />
                    {showArticle && (
                      <Dropdown
                        items={data?.articleDropdown}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowArticle(false);
                          setIsArticleDrop(false);
                        }}
                        activeClass="active-dropdown"
                      />
                    )}
                  </span>
                </OutsideClickHandler>
                <div>
                  <NavLink
                    to={data?.links?.roadmap?.slug}
                    className={({ isActive }) =>
                      isActive ? "active-background" : null
                    }
                  >
                    {data?.links?.roadmap?.linkText}
                  </NavLink>
                </div>
                <div>
                  <NavLink
                    to={data?.links?.whitepaper?.slug}
                    className={({ isActive }) =>
                      isActive ? "active-background whitepaper" : "whitepaper"
                    }
                  >
                    {data?.links?.whitepaper?.linkText}
                  </NavLink>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {isHomePage ?
        null
        :
        <>
          {!waitlistState && (
            <JoinWaitlistAlert handleWaitListModal={handleWaitListModal} />
          )}

          {waitListModal ? (
            <WaitListModal
              show={waitListModal}
              close={handleWaitListModal}
              disableClickOutside={false}
            />
          ) : null}
        </>
      }
    </>
  );
}

export default Header;
