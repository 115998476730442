const PdfLeftArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={34}
    fill="none"
    {...props}
  >
    <path
      stroke="#3C0061"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.333}
      d="m18.667 22-5-5 5-5"
    />
    <path
      stroke="#3C0061"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.333}
      d="M2 5.333A3.333 3.333 0 0 1 5.333 2h23.334A3.333 3.333 0 0 1 32 5.333v23.334A3.333 3.333 0 0 1 28.667 32H5.333A3.333 3.333 0 0 1 2 28.667V5.333Z"
    />
  </svg>
);
export default PdfLeftArrow;
