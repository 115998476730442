const DangerAlert = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#DA0000"
      d="M11.228.958a1.412 1.412 0 0 0-2.45 0L.206 15.54c-.57.973.114 2.209 1.225 2.209h17.142c1.11 0 1.797-1.238 1.225-2.209L11.228.958ZM10 5.25a1.13 1.13 0 0 1 1.125 1.244l-.437 4.383a.69.69 0 0 1-1.375 0l-.438-4.383A1.131 1.131 0 0 1 10 5.25Zm.003 7.5a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5Z"
    />
  </svg>
)
export default DangerAlert
