import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter as Router } from "react-router-dom"
import App from "./App"
import { WaitListContextProvider } from "./context/WaitListContext"
import { CookiesContextProvider } from "./context/CookiesContext"
// import ErrorBoundary from "./ErrorBoundary"
import { HelmetProvider } from "react-helmet-async"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  // <ErrorBoundary>
  <HelmetProvider>
    <WaitListContextProvider>
      <CookiesContextProvider>
        <Router>
          <App />
        </Router>
      </CookiesContextProvider>
    </WaitListContextProvider>
  </HelmetProvider>
  // </ErrorBoundary>
)