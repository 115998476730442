import mdFilePath from "../content/roadmap.md";
import { useParseMarkdown } from "../hooks/useParseMarkdown";
import Markdown from "markdown-to-jsx";
import LaunchedSvgIcon from "../components/svg/LaunchedSvgIcon";
import { SEO } from "../components/meta/SEO"
import meta from "../components/meta/meta.json"

const Roadmap = () => {
  const { content, frontmatter } = useParseMarkdown(mdFilePath);

  return (
    <>
      <SEO
        title={meta?.roadmap?.title}
        description={meta?.roadmap?.description}
      />
      <div className="roadmap">
        <h1 className="p-title">{frontmatter?.title}</h1>
        <div className="roadmap__content">
          <Markdown
            options={{
              overrides: {
                LaunchedIcon: {
                  component: LaunchedSvgIcon,
                },
              },
            }}
          >
            {content}
          </Markdown>
        </div>
      </div>
    </>
  );
};

export default Roadmap;
